import {
  Module, VuexModule, Mutation, Action, getModule,
} from 'vuex-module-decorators'
import isScreen from '@/core/screenHelper'
import { isNull } from 'lodash'
import store from '.'

export const DashboardThemes = {
  LIGHT: 'light',
  DARK: 'dark',
}

Object.freeze(DashboardThemes)

@Module({ namespaced: true, store, name: 'layout' })
export default class LayoutModule extends VuexModule {
  public sidebarClose: boolean = false

  public sidebarStatic: boolean = false

  public sidebarActiveElement: any = null

  public dashboardTheme: any = DashboardThemes.DARK

  // Side Panel
  public panel_action: string | null = null

  public show_side_panel: boolean = false

  public side_panel_data: any = null

  public side_panel_id: any = null

  public display_selected_row_info: any = true

  public view_mode: string = 'full'

  public side_panel_callback: any = {
    creative: null,
    company: null,
    user: null,
    profile: null,
  }

  @Mutation
  public toggleViewMode() {
    this.view_mode = this.view_mode === 'compact' ? 'full' : 'compact'
  }

  @Mutation
  public rowInfoToggler(payload: any) {
    this.display_selected_row_info = payload
  }

  @Mutation
  public showSidePanel(payload: any) {
    this.side_panel_data = payload.data
    this.side_panel_callback = {
      creative: null,
      company: null,
      user: null,
      profile: null,
    }
    this.panel_action = payload.name
    this.side_panel_id = payload.id
    this.show_side_panel = true
  }

  @Mutation
  public hideSidePanel(callback: any = null) {
    if (this.panel_action) {
      switch (this.panel_action) {
        case 'creative':
          this.side_panel_callback.creative = callback
          break
        case 'company':
          this.side_panel_callback.company = callback
          break
        case 'user':
          this.side_panel_callback.user = callback
          break
        case 'profile':
          this.side_panel_callback.profile = callback
          break
      }
    }

    if (!callback) this.side_panel_id = null
    this.side_panel_data = null
    this.panel_action = null
    this.show_side_panel = false
  }

  @Mutation
  public clearSidePanelCallback() {
    this.side_panel_id = null
    this.side_panel_callback = {
      creative: null,
      company: null,
      user: null,
      profile: null,
    }
  }

  @Mutation
  public toggleSidebarMutation() {
    const nextState = !this.sidebarStatic

    localStorage.sidebarStatic = nextState
    this.sidebarStatic = nextState

    if (!nextState && (isScreen('lg') || isScreen('xl'))) {
      this.sidebarClose = true
    }
  }

  @Mutation
  public updateSidebar(value: boolean) {
    if (value) {
      this.sidebarClose = value
    } else {
      this.sidebarClose = !this.sidebarClose
    }
  }

  @Mutation
  public updateSidebarActive(index: any) {
    this.sidebarActiveElement = index
  }

  @Mutation
  public updateTheme(payload: any) {
    this.dashboardTheme = payload
  }

  @Action({ commit: 'toggleSidebarMutation' })
  public toggleSidebar() {}

  @Action({ commit: 'updateSidebar' })
  public switchSidebar(value: boolean) {
    return value
  }

  @Action({ commit: 'rowInfoToggler' })
  public toggleRowInfo(payload: boolean) {
    return payload
  }

  @Action
  public handleSwipe(e: any) {
    if (e === 'left') {
      if (!this.sidebarClose) {
        this.context.commit('updateSidebar', true)
      }
    }

    if (e === 'right') {
      if (this.show_side_panel) {
        this.context.commit('hideSidePanel', null)
      } else if (this.sidebarClose) {
        this.context.commit('updateSidebar', false)
      }
    }
  }

  @Action({ commit: 'updateSidebarActive' })
  public changeSidebarActive(index: any) {
    return index
  }

  @Action({ commit: 'updateTheme' })
  public changeTheme(theme: any) {
    return theme
  }
}
