/** @format */

export default class InsightsSettings {
  impressions = {
    enabled: true,
    completion_rate: true,
    hours_watched: true,
    unique_viewers: false,
    frequency: false,
  }

  hour_distribution = {
    enabled: true,
  }

  day_distribution = {
    enabled: true,
  }

  live_impressions = {
    enabled: true,
  }

  non_live_impressions = {
    enabled: true,
  }

  publishers = {
    enabled: true,
  }

  devices = {
    enabled: true,
  }

  orders = {
    enabled: true,
    frequency: false,
    coviewing: false,
    reach_increment: false,
  }

  map = {
    enabled: true,
    reach: false,
  }

  overview_chart = {
    enabled: true,
  }
}
