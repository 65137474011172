import Api from '@/models/Api'
import WebMessage from '@/models/WebMessage'
import moment from 'moment'

const WideNotification = (notification: any, notifications: any): Array<any> => {
  const api = new Api()
  if (notification.action.type.includes('download')) {
    const download = () => {
      api.download(
        `export-download?target=${encodeURIComponent(notification.download_name)}`,
        notification.output_name,
      )
    }
    WebMessage.success(
      "Your file is ready and should start soon. If it doesn't start click in the download button.",
      [
        {
          text: 'Download',
          action: () => {
            download()
          },
        },
      ],
    )
    download()
  }

  // update where process_id
  let index = notifications.findIndex((n: any) => n.data.process_id === notification.process_id)

  if (index >= 0) {
    notifications[index].data = notification
    notifications[index].sub_type = notification.sub_type
    // notifications.created_at = moment().format('YYYY-MM-DD HH:mm:ss')
    notifications.updated_at = moment().format('YYYY-MM-DD HH:mm:ss')
  } else {
    notifications.unshift({
      created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      data: notification,
      id: notification.id,
      notifiable_id: null,
      notifiable_type: null,
      read_at: null,
      type: notification.type,
      sub_type: notification.sub_type,
      updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    })
  }

  return notifications
}

export default WideNotification
