import DataTableAction from './DataTableAction'

// Defining callable handlers

// Hadler to determine badge color
export interface DataTableColorHandler {
  (row: any): string
}

// Hadler for using custom formatting in a field
export interface DataTableFormatterHandler {
  (value: any, key: string, row: any): string
}

// Object type info
export interface DataTableObjectField {
  key: string
  property: string
}

export default class DataTableField {
  // Create model instace
  constructor(
    key: string,
    label: string,
    type: string = 'text',
    show: boolean = true,
    sortable: boolean = true,
    tdClass: string = 'align-middle text-center',
    metadata:
      | null
      | string
      | DataTableColorHandler
      | DataTableObjectField
      | Array<DataTableAction> = null,
    formatter: null | DataTableFormatterHandler = null,
  ) {
    if (
      type === 'object'
      && (!metadata
        || typeof metadata === 'string'
        || !('key' in metadata)
        || !('property' in metadata))
    ) {
      throw new Error('DataTableField: metadata field must be an instance of DataTableObjectField')
    }

    this.key = key
    this.label = label
    this.type = type
    this.show = show
    this.sortable = sortable
    this.tdClass = tdClass
    this.formatter = formatter
    if (
      this.type === 'object'
      && metadata
      && typeof metadata !== 'string'
      && 'key' in metadata
      && 'property' in metadata
    ) {
      this.object = metadata as DataTableObjectField
    } else if (
      this.type === 'badge'
      && metadata
      && (typeof metadata === 'string' || typeof metadata === 'function')
    ) {
      this.color = metadata === 'string' ? (metadata as string) : (metadata as DataTableColorHandler)
    } else if (
      (this.type === 'action_list' || this.type === 'action')
      && Array.isArray(metadata)
      && metadata.every(a => a instanceof DataTableAction)
    ) {
      this.actions = metadata
    }
  }

  // Object property name (unique)
  public key: string = ''

  // Header title
  public label: string = ''

  // Allow sorting on column
  public sortable: boolean = true

  // Should be on by default
  public show: boolean = true

  // Table TD Class
  public tdClass: string = ''

  // Header class
  public thClass: string = 'align-middle'

  // Field formatting Type (checkbox, date, datetime, time, boolean, capitalize, currency, json, badge, object, action, action_list, text [default])
  public type: string = 'text'

  // Object data, required if type === 'object'
  public object: null | DataTableObjectField = null

  // Defines badge color (required if badge)
  public color: string | DataTableColorHandler = ''

  // Defines formatting function
  public formatter: null | DataTableFormatterHandler = null

  // Data Table row Actions
  public actions: Array<DataTableAction> = []
}
