/** @format */

import Vue from 'vue'

Vue.filter('int_time', (value: number) => {
  value /= 1000
  if (value < 60) {
    return `00:00:${value
      .toFixed(3)
      .toString()
      .padStart(6, '0')}`
  }
  let min = parseInt((value / 60).toFixed(0))
  value -= min * 60
  if (min < 60) {
    return `00:${min.toString().padStart(2, '0')}:${value
      .toFixed(3)
      .toString()
      .padStart(6, '0')}`
  }

  const hour = parseInt((min / 60).toFixed(0))
  min -= hour * 60

  return `${hour
    .toString()
    .padStart(2, '0')}:${min.toString().padStart(2, '0')}:${value
    .toFixed(3)
    .toString()
    .padStart(6, '0')}`
})
