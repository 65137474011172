import Vue from 'vue'
import DoubleConfirmation from '@/components/DoubleConfirmation/DoubleConfirmation.vue'

export default class WebMessage {
  public static success(message: string, buttons: any = []) {
    Vue.prototype.$snotify.success(message, {
      buttons,
    })
  }

  public static error(message: string, buttons: any = []) {
    Vue.prototype.$snotify.error(message, {
      buttons,
    })
  }

  public static info(message: string, buttons: any = []) {
    Vue.prototype.$snotify.info(message, {
      buttons,
    })
  }

  public static warning(message: string, buttons: any = []) {
    Vue.prototype.$snotify.warning(message, {
      buttons,
    })
  }

  public static prompt(message: string, buttons: any = []) {
    Vue.prototype.$snotify.prompt(message, {
      buttons,
    })
  }

  public static confirm(message: string, title: string = '', options: any = {}): Promise<boolean> {
    const h = this.vm.$createElement
    const messageVNode = h('div', { domProps: { innerHTML: message } })

    return this.vm.$bvModal.msgBoxConfirm([messageVNode], {
      title,
      okTitle: 'Delete',
      cancelVariant: 'danger',
      bodyClass: 'bg-white',
      ...options,
    })
  }

  public static modal(message: string, title: string = '', options: any = {}): Promise<boolean> {
    const h = this.vm.$createElement
    const messageVNode = h('div', { domProps: { innerHTML: message } })

    return this.vm.$bvModal.msgBoxConfirm([messageVNode], {
      title,
      okTitle: 'Close',
      okOnly: true,
      cancelVariant: 'danger',
      bodyClass: 'bg-white',
      ...options,
    })
  }

  // Build
  public static doubleConfirm(
    message: string,
    title: string = '',
    confirmation = '',
    options: any = {},
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let Component = Vue.extend(DoubleConfirmation)
      let instance = new Component({
        propsData: {
          title,
          message,
          confirmation,
          options,
          callback: (value: boolean) => {
            setTimeout(() => {
              instance.$destroy()
              instance.$el.remove()
            }, 1000)
            resolve(value)
          },
        },
      })
      instance.$mount()
    })
  }

  public static async(message: string, callback: any) {
    Vue.prototype.$snotify.async(message, callback)
  }

  public static hide(id: string) {
    Vue.prototype.$snotify.remove(id)
  }

  public static hideAll() {
    Vue.prototype.$snotify.clear()
  }

  private static _vm: any

  public static get vm() {
    if (!this._vm) {
      this._vm = new Vue()
    }
    return this._vm
  }
}
