/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import router from '@/Routes'
import Vue from 'vue'
import User from '@/models/User'
import moment from 'moment'
import Api from '@/models/Api'
import WebMessage from '@/models/WebMessage'
import Forecast from '@/models/Forecast'
import { DeviceUUID } from 'device-uuid'
import axios from 'axios'
import WideNotification from './WideNotification'

@Module({ namespaced: true, store, name: 'system' })
export default class SystemtModule extends VuexModule {
  public login: boolean = false

  public _uuid: string = ''

  public device_uuid: string = ''

  public mfa_token: string = ''

  public login_error: string = ''

  public network: any = navigator.onLine

  public enviroment: string = process.env.VUE_APP_ENV || 'dev'

  public version: string = process.env.VUE_APP_VERSION || '1'

  public loading: any = {
    system: true as boolean,
    dashboard: false as boolean,
  }

  public token: string | null = localStorage.getItem('remember_token')

  public csrf: string | null = null

  public api_token: string = ''

  public last_update: string | null = null

  public user: User | null = null

  public notifications: any = []

  public target: any = null

  public forecastModel: Forecast | null = null

  public is_quickbooks_connected: boolean = false

  public mfa_alert_fired: boolean = false

  public env_vars: any = {}

  public filters: Array<any> = []

  public fields: any = {}

  public data: any = {
    // TODO: remove later
    target: null as any,
  }

  @Mutation
  public startLoading(tag: string) {
    if (tag == 'dashboard') {
      this.loading.dashboard = true
    } else {
      this.loading.system = true
    }
  }

  @Mutation
  public finishLoading(tag: string) {
    if (tag == 'dashboard') {
      this.loading.dashboard = false
    } else {
      this.loading.system = false
    }
  }

  @Mutation
  public updateState(payload: any) {
    switch (payload.name) {
      case 'target':
        this.target = payload.data
        this.data.target = this.target
        break
      case 'user':
        this.user = payload.data
        this.data.user = this.user
        break
      case 'notifications':
        this.notifications = payload.data
        break
      case 'env_vars':
        this.env_vars = payload.data
        break
      case 'is_quickbooks_connected':
        this.is_quickbooks_connected = payload.data
        break
      case 'forecast':
        this.forecastModel = payload.data
        break
      case 'filters':
        this.filters[payload.type] = payload.data
        break
      case 'fields':
        if (payload.type) {
          this.fields[payload.type] = payload.data
          localStorage.setItem('fields', JSON.stringify(this.fields))
        } else {
          this.fields = payload.data
        }
        break
    }
  }

  @Mutation
  public setUUID(uuid: string) {
    this._uuid = uuid
  }

  @Mutation
  public setMFAToken(token: string) {
    this.mfa_token = token
  }

  @Mutation
  public setMFAAlertFired(val: boolean) {
    this.mfa_alert_fired = val
  }

  @Mutation
  public setLoginError(error: string) {
    this.login_error = error
  }

  @Mutation
  public setDeviceUUID() {
    this.device_uuid = new DeviceUUID().get()
  }

  @Mutation
  public updateCSRF(token: string) {
    this.csrf = token
  }

  @Mutation
  public updateApiToken(token: string) {
    this.api_token = token
  }

  @Mutation
  public updatelastUpdate(date: string) {
    this.last_update = date
  }

  @Mutation
  public updateNetworkStatus(status: any) {
    this.network = status
  }

  @Mutation
  public clearData() {
    this.login = false
    this.network = navigator.onLine
    this.loading = {
      system: false as boolean,
      dashboard: false as boolean,
    }
    this.token = null
    this.csrf = null
    this.last_update = null
    this.user = null
    this.target = null
    this.api_token = ''
  }

  @Action
  public async init(token: string = '') {
    this.context.commit('updateState', {
      name: 'fields',
      data: JSON.parse(localStorage.getItem('fields') || '{}'),
    })
    this.context.commit('setDeviceUUID')
    this.context.commit(
      'setUUID',
      'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0
        const v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }),
    )
    if (this.user) {
      this.context.dispatch('sync')
    } else if (this.token) {
      const api = new Api()
      api
        .get(
          'user/login-token',
          {
            remember_token: this.token,
            token,
          },
          false,
        )
        .then(response => {
          // Parse and Store User object
          const user = User.toObject(response.data.result.user)

          // Save User into System State
          this.context.commit('updateState', {
            name: 'user',
            data: user,
          })

          if (user.isSuperAdmin) {
            localStorage.setItem('is_root_company', 'true')
          } else {
            localStorage.removeItem('is_root_company')
          }

          // Store api token
          if (response.data.result.api_token) {
            this.context.commit('updateApiToken', response.data.result.api_token)
          }

          // Store csrf token
          this.context.commit('updateCSRF', response.data.result.csrf)

          // Start data synchronization process.
          this.context.dispatch('sync')
        })
        .catch(e => {
          if (e.response.status === 403) localStorage.removeItem('remember_token')
          this.context.commit('finishLoading', 'system')
        })
    } else {
      this.context.commit('finishLoading', 'system')
      let is_root_company = localStorage.getItem('is_root_company')
      // @ts-ignore
      if (window.google && process.env.VUE_APP_GOOGLE_CLIENT_ID && is_root_company === 'true') {
        this.context.dispatch('googleSignIn')
      } else {
        setTimeout(() => {
          this.context.dispatch('googleSignIn')
        }, 1000)
      }
    }
  }

  @Action
  public googleSignIn() {
    // @ts-ignore
    if (!window.google) return
    // @ts-ignore
    window.google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      auto_select: true,
      callback: (a: any) => {
        const api = new Api()
        api
          .get(`oauth/google/signin/${this.device_uuid}/${a.credential}`, {}, false)
          .then(response => {
            if (response.data.message === 'pending_mfa') {
              this.context.commit('setMFAToken', response.data.result.token)
              return
            }
            // Parse and Store User object
            const user = User.toObject(response.data.result.user)

            // Save User into System State
            this.context.commit('updateState', {
              name: 'user',
              data: user,
            })

            // Store api token
            if (response.data.result.api_token) {
              this.context.commit('updateApiToken', response.data.result.api_token)
            }

            // Store csrf token
            this.context.commit('updateCSRF', response.data.result.csrf)

            // Start data synchronization process.
            this.context.dispatch('sync')
          })
          .catch(e => {
            if (e.response.status === 403) localStorage.removeItem('remember_token')
            if (e.response.status === 422) {
              this.context.commit('setLoginError', e.response.data.message)
            }
            // @ts-ignore
            window.google.accounts.id.disableAutoSelect()
            this.context.commit('finishLoading', 'system')
          })
      },
    })
    // @ts-ignore
    window.google.accounts.id.prompt()
  }

  @Action
  public logout() {
    router.push('/login')
    // @ts-ignore
    if (window.google) window.google.accounts.id.disableAutoSelect()
    localStorage.removeItem('remember_token')
    this.context.commit('clearData')
  }

  @Action
  public getFilter(type: any) {
    return this.filters[type] ?? null
  }

  @Action
  public getFields(type: any) {
    return this.fields[type] ?? null
  }

  @Action
  public checkVersion(type: any) {
    axios.get('https://stag.dashboard.castiron.media/version.txt').then(response => {
      let remote = response.data
      // console.log(remote, this.version)
    })
  }

  @Action
  public sync() {
    this.context.commit('startLoading', 'system')
    const api = new Api()
    api
      .get('sync')
      .then(response => {
        if (response.data.last_update) {
          this.context.commit(
            'updatelastUpdate',
            moment(response.data.last_update).format('MMMM, Do YYYY HH:mm Z'),
          )
        }

        // User
        this.context.commit('updateState', {
          name: 'user',
          data: User.toObject(response.data.user),
        })

        // Notifications
        this.context.commit('updateState', {
          name: 'notifications',
          data: response.data.notifications.map((n: any) => {
            n.sub_type = n.data.sub_type
            return n
          }),
        })

        // Env vars
        this.context.commit('updateState', {
          name: 'env_vars',
          data: response.data.env_vars,
        })

        // Quickbooks
        this.context.commit('updateState', {
          name: 'is_quickbooks_connected',
          data: response.data.is_quickbooks_connected,
        })

        Vue.prototype.$echo.options.auth.headers.Authorization = `Bearer ${response.data.user.api_token}`
        Vue.prototype.$echo.connector.pusher.config.auth = {
          headers: { Authorization: `Bearer ${response.data.user.api_token}` },
        }

        Vue.prototype.$echo.private(`user.${this.user!.id}`).listen('ReportReady', (e: any) => {
          if ((!e.instance_id && !this._uuid) || e.instance_id === this._uuid) {
            const api = new Api()

            api.download(`export-download?target=${encodeURIComponent(e.report)}`, e.output_name)

            this.context.commit('finishLoading', 'dashboard')
            WebMessage.success(
              "Your file is ready and should start soon. If it doesn't start click in the download button.",
              [
                {
                  text: 'Download',
                  action: () => {
                    const api = new Api()
                    api.download(
                      `export-download?target=${encodeURIComponent(e.report)}`,
                      e.output_name,
                    )
                  },
                },
              ],
            )
          }
        })

        Vue.prototype.$echo.private(`user.${this.user!.id}`).listen('MessageUI', (e: any) => {
          if (!e.instance_id || e.instance_id === this._uuid) {
            this.context.commit('finishLoading', 'dashboard')
            if (e.type === 'error') WebMessage.error(e.message)
            else if (e.type === 'success') WebMessage.success(e.message)
            else WebMessage.warning(e.message)
          }
        })

        Vue.prototype.$echo.private(`user.${this.user!.id}`).notification((notification: any) => {
          let notifications = this.notifications
          if (
            notification.action
            && notification.action.type
            && (!notification.instance_id || notification.instance_id === this._uuid)
          ) {
            notifications = WideNotification(notification, notifications)
          } else {
            notifications.unshift({
              created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
              data: notification,
              id: notification.id,
              notifiable_id: null,
              notifiable_type: null,
              read_at: null,
              type: notification.type,
              sub_type: notification.sub_type,
              updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            })
          }

          this.context.commit('updateState', {
            name: 'notifications',
            data: notifications,
          })
          if (!notification.instance_id || notification.instance_id === this._uuid) {
            if (!notification.silent) {
              const audio = new Audio('/notification.mp3')
              audio.volume = 0.7
              audio.play()
            }
            if (!notification.action || !notification.action.type) {
              WebMessage.info(notification.message)
            }
          }
        })

        if (router.currentRoute.name == 'Login') {
          let { target } = this
          if (target) {
            this.context.commit('updateState', {
              name: 'target',
              data: null,
            })
          }
          if (!target) {
            if (this.user?.home?.includes('/')) {
              target = { path: this.user!.home }
            } else {
              target = { name: this.user!.home }
            }
          }

          setTimeout(() => {
            this.context.commit('finishLoading', 'system')
          }, 1000)

          router.push(target)
        } else {
          setTimeout(() => {
            this.context.commit('finishLoading', 'system')
          }, 500)
        }
      })
      .catch((e: any) => {
        this.context.commit('updateState', {
          name: 'user',
          data: null,
        })
        router.push('/login')
        this.context.commit('finishLoading', 'system')
      })
  }
}
