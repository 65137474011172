import User from '@/models/User'
import moment from 'moment'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import { EventBus } from './eventBus'

export default (user: any, next: any = null) => {
  let today = moment()
  const systemModule = getModule(SystemtModule)

  if (user.mfa_required_at && today.isSameOrAfter(user.mfa_required_at) && !user.mfa_enabled) {
    setTimeout(() => {
      next({ name: 'mfa-required' })
    }, 1000)
    return
  }

  if (systemModule.mfa_alert_fired) {
    return
  }

  let last_date = localStorage.getItem('mfa-remind-me')

  if (last_date && last_date.length > 2) {
    if (today.isSameOrAfter(last_date) && !user.mfa_enabled) {
      setTimeout(() => {
        systemModule.setMFAAlertFired(true)
        EventBus.$emit('mfa-alert-notification', true)
      }, 2500)
    }
  } else if (!user.mfa_enabled && user.mfa_required_at) {
    if (today.isBefore(user.mfa_required_at)) {
      setTimeout(() => {
        systemModule.setMFAAlertFired(true)
        EventBus.$emit('mfa-alert-notification', true)
      }, 2500)
    }
  }
}
