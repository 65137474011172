import store from '@/store'
import {
  isArray, isEqual, isObject, transform,
} from 'lodash'

export default {
  data() {
    return {

    }
  },
  methods: {
    isPromise(promise: any) {
      return !!promise && typeof promise.then === 'function'
    },
    normalizePublisherName(target: string) {
      return target
      const publisher = target.toLowerCase()
      if (publisher.includes('mlb')) {
        return 'MLB.TV'
      } if (publisher.includes('nhl')) {
        return 'NHL.TV'
      } if (publisher.includes('nba')) {
        return 'NBA.TV'
      }

      return target
    },
    checkIfValidUUID(str: any) {
      // Regular expression to check if string is a valid UUID
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

      return regexExp.test(str)
    },

    difference(origObj: any, newObj: any) {
      const changes = (newObj: any, origObj: any) => {
        let arrayIndexCounter = 0
        return transform(newObj, (result: any, value: any, key: any) => {
          if (!isEqual(value, origObj[key])) {
            let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
            result[resultKey] = isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value
          }
        })
      }
      return changes(newObj, origObj)
    },
    vClone(data: any) {
      return JSON.parse(JSON.stringify(data))
    },
  },
  filters: {
    abbreviate_full(n: any) {
      const a = n.slice(-1)
      switch (a) {
        case 'm':
          n = n.replace('m', ' Million')
          break
        case 'b':
          n = n.replace('b', ' Billion')
          break
        case 't':
          n = n.replace('t', ' Trillion')
          break
      }
      return n
    },
  },
}
