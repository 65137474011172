
import ViewModel from '@/models/ViewModel'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class ReviewHome extends ViewModel {
  @Prop({ default: '' }) title!: string

  @Prop({ default: '' }) message!: string

  @Prop({ default: '' }) confirmation!: string

  @Prop({ default: () => null }) callback!: any

  public showModal = true

  public confirm: boolean = false

  public onClose(result: boolean) {
    if (this.callback) this.callback(result)
  }
}
