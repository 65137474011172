import { clone as _clone } from 'lodash'
import { randomUUID } from './interface/Common'

export default class DeliveryGoal {
  public id: string = ''

  public start_at: string = ''

  public end_at: string = ''

  public goal: number = 0

  public impressions: number = 0

  public external_impressions: number = 0

  // when its deliver_goal
  public overwrite_pool_size: boolean = false

  // if overwrite is true
  // float 0.1 -10
  public pool_size: number = 0.1 // pool_size > 0 && overwrite_pool_size == true

  public discard_external_impressions: boolean = false

  public metrics: any = {}

  public get progress() {
    return Number(this.impressions) + Number(this.external_impressions)
  }

  public toObject(source: any) {
    let instance = this.clone()
    Object.assign(instance, source)

    return instance
  }

  public clone() {
    const clone = _clone(this)
    return clone
  }

  public static toObject(source: any): DeliveryGoal {
    return new DeliveryGoal().toObject(source)
  }

  public toObjectList(source: any) {
    return source.map((item: any) => this.toObject(item))
  }

  public static toObjectList(source: any): DeliveryGoal[] {
    return new DeliveryGoal().toObjectList(source)
  }
}
