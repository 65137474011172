/** @format */

import Company from './Company'
import FeatureAccess from './FeatureAccess'
import defaultPermissions from './DefaultPermissions'
import Model from './interface/Model'
import ApiParameters from './interface/ApiParameters'

export default class Profile extends Model {
  public api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'profile' as string | null,
      plural: 'profiles' as string | null,
    },
  }

  public id: string | null = null

  public name: string = ''

  public company_id: string = ''

  public station_id: string = ''

  public default: boolean = false

  public system: boolean = false

  public root: boolean = false

  public created_at: string = ''

  public updated_at: string = ''

  public access: FeatureAccess[] = JSON.parse(JSON.stringify(defaultPermissions))

  public company: Company = new Company()

  public station: Company = new Company()

  public getAccessIndex(model: string, action: string) {
    return this.access.findIndex(item => item.model === model && item.action === action)
  }

  public can(model: string, action: string = 'any', company_level: boolean = false) {
    let permission
    if (action == 'any') {
      permission = this.company.access.find(p => p.model === model && p.access === true)
    } else {
      permission = this.company.access.find(p => p.model === model && p.action === action)
    }

    if (!permission || company_level) {
      return permission ? permission.access : false
    }

    if (action == 'any') {
      permission = this.access.find(p => p.model === model && p.access === true)
    } else {
      permission = this.access.find(p => p.model === model && p.action === action)
    }

    if (!permission) {
      return false
    }
    return permission.access
  }

  public canOptions(model: string, action: string = 'any') {
    const permissions = this.access.find(p => p.model === model && p.action === 'options')
    return permissions ? permissions.options.includes(action) : false
  }

  private _actions: string[] = []

  public get actions() {
    if (this._actions.length == 0) {
      this._actions = this.access
        .map(item => item.action)
        .filter((value, index, self) => self.indexOf(value) === index)
    }

    return this._actions
  }

  private _models: string[] = []

  public get models() {
    if (this._models.length == 0) {
      this._models = this.access
        .map(item => item.model)
        .filter((value, index, self) => self.indexOf(value) === index)
    }

    return this._models
  }

  public hasAction(model: string, action: string) {
    return this.access.some(item => item.model === model && item.action === action)
  }

  public toObject(source: any) {
    let instance = this.clone()

    for (const model in source.access) {
      for (const idx in source.access[model]) {
        const action = source.access[model][idx]
        const index = instance.getAccessIndex(model, action)
        if (index >= 0) instance.access[index].access = true
      }
    }

    if (source.access_options) {
      for (const model in source.access_options) {
        const index = instance.getAccessIndex(model, 'options')
        if (index >= 0) instance.access[index].options = source.access_options[model]
      }
      delete source.access_options
    }

    if (source.company) {
      instance.company = Company.toObject(source.company)
    }

    if (source.station) {
      instance.station = Company.toObject(source.station)
    }

    // Remove dynamic data
    if (source.access !== undefined) delete source.access
    if (source.company !== undefined) delete source.company
    if (source.station !== undefined) delete source.station

    Object.assign(instance, source)

    return instance
  }

  public get apiData() {
    let access_options: any = {}
    this.access.forEach(item => {
      if (item.options.length > 0) {
        access_options[item.model] = item.options
      }
    })
    const data: ApiParameters = {
      name: this.name,
      company_id: this.company_id,
      station_id: this.station_id,
      access: this.access
        .filter(item => item.access)
        .map(item => `${item.model}:${item.action}`),
      access_options,
    }

    if (!this.station_id) {
      delete data.station_id
    }

    return data
  }
}
