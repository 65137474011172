
import { randomUUID } from '@/models/interface/Common'
import { Component, Vue, Prop } from 'vue-property-decorator'
import custom_icons from './custom_icons'

@Component
export default class CustomIcon extends Vue {
  @Prop({ default: 'watch' })
  public icon!: string

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: 'primary' })
  public color!: string

  public get customIconData() {
    // @ts-ignore
    return this.custom_icons[this.icon]
  }

  public get customColor() {
    if (this.statusColor === 'secondary') {
      return '#798892'
    }
    if (this.statusColor === 'danger') {
      return '#f55d5d'
    }
    if (this.statusColor === 'success') {
      return '#3abf94'
    }
    if (this.statusColor === 'warning') {
      return '#ffc247'
    }

    if (this.statusColor === 'white') {
      return '#fff'
    }
    return '#41b883'
  }

  public get custom_icons() {
    return custom_icons
  }

  public get statusColor() {
    return this.disabled !== false ? 'secondary' : this.color
  }
}
