<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  created() {
    const currentPath = this.$router.history.current.path

    if (window.localStorage.getItem('authenticated') === 'false') {
      this.$router.push('/login')
    }

    if (
      (currentPath === '/' || currentPath === '/app')
      && (!window.location.href.includes('billboard') || window.location.href.includes('/app'))
    ) {
      this.$router.push({ name: 'Login' })
    }

    let g = document.createElement('script')
    g.setAttribute('type', 'text/javascript')
    g.setAttribute('src', 'https://accounts.google.com/gsi/client')
    g.setAttribute('async', 'true')
    g.setAttribute('defer', 'true')
    document.head.appendChild(g)

    let gApi = document.createElement('script')
    gApi.setAttribute('type', 'text/javascript')
    gApi.setAttribute('src', 'https://apis.google.com/js/api.js')
    gApi.onload = () => {
      window.gapi.load('picker', () => {})
    }
    document.head.appendChild(gApi)
  },
}
</script>
