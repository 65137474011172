/** @format */

import Api from '@/models/Api'
import ApiParameters from '@/models/interface/ApiParameters'
import InsightsSettings from '@/models/InsightsSettings'
import moment from 'moment'
import defaultPermissions from './DefaultPermissions'
import FeatureAccess from './FeatureAccess'
import DeliveryGoal from './DeliveryGoal'
import Model from './interface/Model'

export default class Company extends Model {
  public api_settings = {
    save_mode: 'form',
    paths: {
      singular: 'company' as string | null,
      plural: 'companies' as string | null,
    },
  }

  public id: string | null = null

  public name: string = ''

  public type: string = 'advertiser'

  public industry: string = ''

  public sub_industry: string = ''

  public parent_id: string | null = null

  public holding_id: string | null = null

  public owner_id: string | null = null

  public invoicing_account_id: string | null = null

  public logo: string | null = null

  public icon: string | null = null

  public logo_white: string | null = null

  public invoicing_id: string | null = null

  public root: boolean = false

  public rate: number = 20

  public home_page: string = 'Insights'

  public created_at: string = ''

  public archived_at: string | null = null

  public updated_at: string = ''

  public third_party_deal: boolean = false

  public default_rate: number = 0

  public dynamic_rate_id: string | null = null

  public tracking_events: any = []

  public default_media_package_id: any = null

  public agency_commission: any = {
    model: 'none',
    mode: 'default',
    value: 0,
    minimum_guaranteed: 0,
  }

  public billing_info: any = {
    idb_code: '',
    idb_name: '',
    country: 'US',
    state: '',
    city: '',
    zipcode: '',
    billing_email: [],
    billing_phone: '',
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    address_line_4: '',
    required_plan_fields: [],
    invoice_group: 'media_plan',
    invoice_detail: 'media_plan_item',
    invoice_template: 'default',
    invoice_delivery_driver: 'email',
    vendor_id: '',
  }

  public metadata: any = {
    idb_code: '',
    idb_name: '',
    ispot_code: '',
  }

  public delivery_goals: DeliveryGoal[] = []

  public delivery_caps: DeliveryGoal[] = []

  public access: FeatureAccess[] = JSON.parse(JSON.stringify(defaultPermissions))

  public insights_settings: InsightsSettings | null = new InsightsSettings()

  public getAccessIndex(model: string, action: string) {
    return this.access.findIndex(item => item.model === model && item.action === action)
  }

  public parent: Company | null = null

  public owner: Company | null = null

  public has_stations: boolean = false

  public region: any = null

  public budget_cycle: any = null

  public fiscal_close: any = null

  public first_advertised_at: any = null

  public last_advertised_at: any = null

  public static option_list: any = {
    budget_cycles: [
      {
        name: 'Monthly',
        value: 'monthly',
      },
      {
        name: 'Quarterly',
        value: 'quarterly',
      },
      {
        name: 'Annually',
        value: 'annually',
      },
    ],
    fiscal_close: moment.monthsShort().map((month, i) => ({
      name: month,
      value: i,
    })),
  }

  public mfa_required: boolean = false

  public get active_distribution_goal() {
    if (!this.delivery_goals.length) return null

    return this.delivery_goals.find(goal => {
      let start = moment(goal.start_at)
      let end = moment(goal.end_at)
      return moment().isBetween(start, end, null, '[]')
    })
  }

  public get active_distribution_cap() {
    if (!this.delivery_caps.length) return null

    return this.delivery_caps.find(goal => {
      let start = moment(goal.start_at)
      let end = moment(goal.end_at)
      return moment().isBetween(start, end, null, '[]')
    })
  }

  public get typeFormatted() {
    let { type } = this

    return type.replace('_', ' ')
  }

  public getLogo() {
    return this.logo
  }

  public getLogoWhite() {
    if (this.logo_white) return this.logo_white
    return this.getLogo()
  }

  public getIcon() {
    if (this.icon) return this.icon
    return this.getLogo()
  }

  public get isBillingInformationComplete() {
    return (
      this.billing_info.country
      && this.billing_info.state
      && this.billing_info.city
      && this.billing_info.zipcode
      && this.billing_info.address_line_1
    )
  }

  public insightStatus(widget: string, metric: string = 'enabled'): boolean {
    if (
      this.insights_settings !== null
      && widget in this.insights_settings
      // @ts-ignore
      && metric in this.insights_settings[widget]
    ) {
      // @ts-ignore
      return this.insights_settings[widget][metric]
    }

    if (this.parent) {
      return this.parent.insightStatus(widget, metric)
    }

    if (this.owner) {
      return this.owner.insightStatus(widget, metric)
    }

    return false
  }

  public can(model: string, action: string = 'any') {
    if (this.root) return true
    let permission

    if (action == 'any') {
      permission = this.access.find(p => p.model === model && p.access === true)
    } else {
      permission = this.access.find(p => p.model === model && p.action === action)
    }

    if (!permission) {
      return false
    }
    return permission.access
  }

  public get apiData() {
    let access_options: any = {}
    this.access.forEach(item => {
      if (item.options.length > 0) {
        access_options[item.model] = item.options
      }
    })
    const data: ApiParameters = {
      name: this.name,
      type: this.type,
      industry: this.industry,
      sub_industry: this.sub_industry,
      third_party_deal: this.third_party_deal ? 1 : 0,
      default_rate: this.default_rate,
      dynamic_rate_id: this.dynamic_rate_id,
      access: this.access
        .filter(item => item.access)
        .map(item => `${item.model}:${item.action}`)
        .join(','),
      rate: this.rate,
      billing_info: JSON.stringify(this.billing_info),
      metadata: JSON.stringify(this.metadata),
      parent_id: this.parent_id,
      owner_id: this.owner_id,
      holding_id: this.holding_id,
      logo: this.logo,
      logo_white: this.logo_white,
      icon: this.icon,

      invoicing_id: this.invoicing_id,
      archived_at: this.archived_at,
      insights_settings:
        this.insights_settings !== null ? JSON.stringify(this.insights_settings) : null,
      agency_commission: JSON.stringify(this.agency_commission),
      delivery_caps: JSON.stringify(this.delivery_caps),
      delivery_goals: JSON.stringify(this.delivery_goals),
      tracking_events: JSON.stringify(this.tracking_events),
      region: this.region,
      budget_cycle: this.budget_cycle,
      fiscal_close: this.fiscal_close,
      default_media_package_id: this.default_media_package_id,
      mfa_required: this.mfa_required,
      access_options: JSON.stringify(access_options),
    }

    if (this.access.filter(item => item.access).length == 0) {
      data.access = ''
    }

    if (this.type != 'station') {
      delete data.parent_id
    }
    if (this.type != 'advertiser') {
      delete data.owner_id
    }

    if (!this.logo) {
      delete data.logo
    }

    if (!this.logo_white) {
      delete data.logo_white
    }

    if (!this.icon) {
      delete data.icon
    }

    if (!this.invoicing_id) {
      delete data.invoicing_id
    }

    return data
  }

  public save(logo: any = null, logo_white: any = null, icon: any = null) {
    const api = new Api()

    let data = this.apiData

    if (logo) {
      data.logo_file = logo
    } else {
      delete data.logo_file
    }
    if (logo_white) {
      data.logo_white_file = logo_white
    } else {
      delete data.logo_white_file
    }

    if (icon) {
      data.icon_file = icon
    } else {
      delete data.icon_file
    }

    if (this.id) {
      return api.form(`company/${this.id}`, data).then(this.onSave).catch(this.onError)
    }
    return api.form('company', data).then(this.onSave).catch(this.onError)
  }

  public toObject(source: any) {
    let instance = this.clone()

    if (source.insights_settings) {
      for (let key in source.insights_settings) {
        // @ts-ignore
        instance.insights_settings[key] = source.insights_settings[key]
      }
      delete source.insights_settings
    }
    if (source.home_page) instance.home_page = source.home_page

    if (source.billing_info && source.billing_info != 'null') {
      instance.billing_info = { ...instance.billing_info, ...source.billing_info }
    }
    if (source.agency_commission && source.agency_commission != 'null') {
      instance.agency_commission = { ...instance.agency_commission, ...source.agency_commission }
    }

    for (const model in source.access) {
      for (const idx in source.access[model]) {
        const action = source.access[model][idx]
        const index = instance.getAccessIndex(model, action)
        if (index >= 0) instance.access[index].access = true
      }
    }

    if (source.access_options) {
      for (const model in source.access_options) {
        const index = instance.getAccessIndex(model, 'options')
        if (index >= 0) instance.access[index].options = source.access_options[model]
      }
      delete source.access_options
    }

    if (source.parent) {
      instance.parent = Company.toObject(source.parent)
      delete source.parent
    }

    if (source.owner) {
      instance.owner = Company.toObject(source.owner)
      delete source.owner
    }

    if (source.delivery_caps) {
      instance.delivery_caps = DeliveryGoal.toObjectList(source.delivery_caps)
      delete source.delivery_caps
    }

    if (source.delivery_goals) {
      instance.delivery_goals = DeliveryGoal.toObjectList(source.delivery_goals)
      delete source.delivery_goals
    }

    if (source.metadata) {
      instance.metadata = { ...instance.metadata, ...source.metadata }
    }
    delete source.metadata

    // Remove dynamic data
    if (source.access !== undefined) delete source.access
    if (source.billing_info !== undefined) delete source.billing_info
    if (source.agency_commission !== undefined) delete source.agency_commission
    if (source.insights_settings !== undefined) delete source.insights_settings
    if (source.home_page !== undefined) delete source.home_page

    instance.tracking_events = source.tracking_events ? source.tracking_events : []
    if (!source.tracking_events) delete source.tracking_events

    Object.assign(instance, source)

    return instance
  }

  public static getCompanyAmounts(id: any) {
    let api = new Api()

    return api.get(`company/${id}/amounts`).then(response => response.data)
  }

  public static activeGoalStatus(payload: any) {
    let api = new Api()

    return api.post(`company/${payload.id}/ditribution/status`).then(response => response.data)
  }

  public static activeGoalMetrics(payload: any) {
    let api = new Api()

    return api.post(`company/${payload.id}/ditribution/metrics`).then(response => response.data)
  }

  public static activeGoal30Days(payload: any) {
    let api = new Api()

    return api.post(`company/${payload.id}/ditribution/30-days`).then(response => response.data.result)
  }

  public static activeGoal30DaysLegacy(payload: any) {
    let api = new Api()

    return api.post(`company/${payload.id}/ditribution/30-days`).then(response => response.data)
  }

  public static realtimeMetrics(payload: any) {
    let api = new Api()

    return api.post(`company/${payload.id}/ditribution/real-time`).then(response => response.data)
  }
}
