import DataTableAction from './DataTableAction'
import DataTableField from './DataTableField'

/**
 * Creates a new columns in the DataTable of type Action or Action List
 */
export default class DataTableActionField extends DataTableField {
  constructor(
    actions: Array<DataTableAction>,
    type: string = 'action_list',
    show: boolean = true,
    tdClass: string = 'align-middle text-center',
  ) {
    super('action', '', type, show, false, tdClass, actions, null)
  }
}
