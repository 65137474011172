
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'
import IconAction from '@/components/IconAction/IconAction.vue'
import SelectOption from '@/models/interface/SelectOption'
import { isString } from 'lodash'

@Component({
  components: {
    Multiselect,
    IconAction,
  },
})
export default class SelectPicker extends Vue {
  @Prop({ default: () => [] })
  public value!: any

  @Prop({ default: () => null })
  public selectedName!: any

  @Prop({ default: () => '' })
  public groupValues!: any

  @Prop({ default: () => '' })
  public groupLabel!: any

  @Prop({ default: () => false })
  public groupSelect!: any

  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: () => [] })
  public label_name!: string | string[]

  @Prop({ default: false })
  public disableLabelClick!: boolean

  @Prop()
  public name!: string

  public label_value: string = 'N/A'

  @Prop({ default: '' })
  public vid!: string

  @Prop({ default: false })
  public actionEdit!: boolean

  @Prop({ default: '' })
  public label!: string

  @Prop({ default: 'name' })
  public label_field!: string

  @Prop({ default: false })
  public multiple!: boolean

  @Prop({ default: true })
  public ready!: boolean

  @Prop({ default: false })
  public busy!: boolean

  @Prop({ default: false })
  public vertical!: boolean

  @Prop({ default: true })
  public internal_search!: boolean

  @Prop({ default: true })
  public close_on_select!: boolean

  @Prop({ default: false })
  public hide_selected!: boolean

  @Prop({ default: false })
  public taggable!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: false })
  public allow_empty!: boolean

  @Prop({ default: null })
  public tagAction!: any

  @Prop({ default: () => [] })
  public options!: Array<any>

  @Prop({ default: '' })
  public rules!: [Object, String]

  @Prop({ default: 'input' })
  public id!: string

  public secrect: string = ''

  @Prop({ default: 'Select' })
  public placeholder!: string

  @Prop({ default: null })
  public overwrite_local_value!: string

  @Watch('overwrite_local_value')
  public overwriteLocalValue(val: any) {
    if (val) {
      this.local_value = val
    }
  }

  public local_value: any = null

  public update: boolean = true

  public get has_description(): boolean {
    return this.options && this.options.length > 0 && this.options[0].description
  }

  public get uuid() {
    return `${this.id}-${this.secrect}`
  }

  public get formLabel() {
    if (typeof this.rules !== 'string') {
      return this.label ? this.label + (this.rules.required ? ' *' : '') : this.label
    }
    return this.label ? this.label + (this.rules.includes('required') ? ' *' : '') : this.label
  }

  public addTag(newTag: string) {
    if (this.tagAction) {
      this.tagAction(newTag)
    }
  }

  public searchChange(search: string) {
    this.$emit('search', search)
  }

  public open(id: string) {
    this.$emit('open', id)
  }

  @Watch('local_value')
  public onChangeValue(val: any) {
    if (!this.ready) return
    if (val == null) {
      this.$emit('input', null)
    } else if (this.multiple !== false) {
      this.$emit(
        'input',
        val.map((o: any) => o.value),
      )
      this.$emit(
        'update:label_name',
        val.map((o: any) => o.name),
      )
    } else {
      this.$emit('input', val.value)
      this.$emit('update:label_name', val.name)

      if (this.local_value !== undefined) this.$emit('selected', { name: val.name })
    }
    this.update = false
    if (val && val.name) this.$emit('selected', val)
    setTimeout(() => {
      this.update = true
    }, 1000)
  }

  @Watch('value')
  public onChangeProp(val: any) {
    if (this.update) {
      if (val == null) {
        this.local_value = null
      } else if (this.multiple !== false) {
        this.local_value = this.options.filter((o: any) => this.value.includes(o.value))
      } else {
        this.local_value = this.options.find((o: any) => String(o.value) === String(this.value))
      }
    }
    this.update = true
  }

  public mounted() {
    this.secrect = String(Math.floor(Math.random() * Math.floor(999)))
  }

  public created() {
    if (this.groupValues && this.groupValues.length) {
      if (isString(this.value)) {
        this.local_value = { value: this.value, name: this.selectedName }
      }
    } else if (this.value || this.value === false || this.value === 0) {
      if (this.multiple !== false) {
        this.local_value = this.options.filter((o: any) => this.value.includes(o.value))
      } else {
        this.local_value = this.options.find((o: any) => String(o.value) === String(this.value))
      }
    }

    this.updateLabel()
  }

  public updateLabel() {
    if (!this.value) {
      this.label_value = 'N/A'
    } else if (!Array.isArray(this.value)) {
      // @ts-ignore
      this.label_value = this.options.find(
        (o: SelectOption) => String(o.value) === String(this.value),
        // @ts-ignore
      )?.name
    }
  }
}
