
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Fuse from 'fuse.js'
import FaqModel from '@/models/FaqModel'
import IconAction from '../IconAction/IconAction.vue'
import FormInput from '../FormInput/FormInput.vue'

@Component({
  components: {
    IconAction,
    FormInput,
  },
})
export default class FaqModal extends ViewModel {
  @Prop()
  public module!: any

  @Prop()
  public settings!: any[]

  public modal: boolean = false

  public loading: boolean = true

  public search_query: any = null

  public fuse_options: any = {
    includeScore: true,
    keys: ['question', 'answer'],
    findAllMatches: false,
    includeMatches: true,
    threshold: 0.3,
    ignoreLocation: true,
    minMatchCharLength: 2,
  }

  public fuse: any = null

  public faqs: any = []

  @Watch('search_query')
  public onSearchQueryChange(val: any, old_val: any) {
    if (old_val && (!val || !val.length)) {
      this.reset()
    }
  }

  public loadFuseSettings() {
    let settings = this.fuse_options
    if (this.settings) {
      settings = { ...this.fuse_options, ...this.settings }
    }
    this.fuse = new Fuse(this.faqs, settings)
  }

  public mounted() {
    this.loading = true

    this.loadFaqs()
  }

  public reset() {
    if (!this.faqs || !this.faqs.length) return []
    this.faqs = this.faqs.map(f => {
      f.show = true
      return f
    })
  }

  public get list() {
    if (!this.fuse) return this.faqs
    if (!this.search_query || !this.search_query.length) return this.faqs

    const result = this.fuse.search(this.search_query)

    let indexes = result.map(r => r.refIndex)

    return this.faqs.map((f, idx) => {
      if (indexes.includes(idx)) {
        f.show = true
      } else {
        f.show = false
      }
      return f
    })
  }

  public loadFaqs() {
    return FaqModel.query({ module: this.module }).then(result => {
      this.faqs = result
      this.loadFuseSettings()
      this.loading = false
    })
  }
}
