/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {

    },
    registered() {
      // console.log('Service worker has been registered.')
    },
    cached() {
      // console.log('Content has been cached for offline use.')
    },
    updatefound() {
      // console.log('New content is downloading.')
    },
    updated() {
      /*
      Messenger().post({
        message: 'The platform was updated and we have a new version available; please close this window and reopen it!',
        type: 'info',
        showCloseButton: true,
        hideAfter: 10,
        hideOnNavigate: true,
      }); */
    },
    offline() {
      // @ts-ignore
      Messenger().post({
        message: 'No internet connection found. App is running in offline mode. The data might be outdated and some features might not work as expected',
        type: 'info',
        showCloseButton: true,
        hideAfter: 10,
        hideOnNavigate: true,
      })
    },
    error(error) {
      // console.error('Error during service worker registration:', error)
    },
  })
}
