
import { Component, Vue, Prop } from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { BPopover } from 'bootstrap-vue'
import FaqModal from '@/components/FaqModal/FaqModal.vue'

@Component({
  components: {
    IconAction,
    BPopover,
    SelectPicker,
    FaqModal,
  },
})
export default class Widget extends Vue {
  @Prop({ default: 'top' })
  public tooltipPlacement!: string

  @Prop({ default: true })
  public showTooltip!: boolean

  @Prop({ default: false })
  public close!: boolean

  @Prop({ default: false })
  public fullscreen!: boolean

  @Prop({ default: false })
  public collapse!: boolean

  @Prop({ default: false })
  public collapsed!: boolean

  @Prop({ default: false })
  public columns!: boolean

  @Prop({ default: '' })
  public subTitle!: string

   @Prop({ default: '' })
  public faq!: string

  @Prop({ default: () => '' })
  public help!: string

  @Prop({
    default: () => [],
  })
  public column_fields!: any

  @Prop({
    default: () => [],
  })
  public extra_column_fields!: any

  @Prop()
  public extra_column_title!: any

  @Prop({ default: false })
  public settings!: boolean

  @Prop({ default: false })
  public refresh!: boolean

  @Prop({ default: false })
  public resetFilters!: boolean

  @Prop({ default: false })
  public filter!: boolean

  @Prop({ default: false })
  public download!: boolean

  @Prop({ default: false })
  public pagination!: boolean

  @Prop({ default: '' })
  public title!: string

  @Prop({ default: false })
  public busy!: boolean

  @Prop({ default: false })
  public loading!: boolean

  @Prop({ default: false })
  public hideControls!: boolean

  public visible: boolean = true

  public columns_visible: boolean = false

  public secret: string = ''

  public pagination_options: any = [
    { name: '10', value: '10' },
    { name: '50', value: '50' },
    { name: '100', value: '100' },
  ]

  public collapseWidget(val: boolean) {
    this.visible = !val
  }

  public mounted() {
    if (this.collapsed !== false) {
      this.visible = false
    }

    this.secret = String(Math.floor(Math.random() * Math.floor(999)))
  }
}
