import Api from './Api'
import WebMessage from './WebMessage'
import Model from './interface/Model'

export default class FaqModel extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'faq' as string | null,
      plural: 'faqs' as string | null,
    },
  }

  public id: any = null

  public module: string = ''

  public module_actions: string = 'view'

  public question: string = ''

  public answer: string = ''

  public tags: string[] = []

  public created_at: any = null

  public updated_at: any = null

  public show: boolean = true

  public get moduleName() {
    const ret = this.module_listing.find((n: any) => n.value === this.module)
    if (ret) return ret.name
    return ''
  }

  public onSave(msg: any) {
    WebMessage.success(`Question for ${this.moduleName} Saved`)
    return msg
  }

  public get apiData() {
    return {
      id: this.id,
      module: this.module,
      module_actions: this.module_actions,
      question: this.question,
      answer: this.answer,
      tags: this.tags,
    }
  }

  public static module_list = [
    {
      name: 'Media Plan',
      value: 'media_plan',
    },
    {
      name: 'Max Avails',
      value: 'max_avails',
    },
    {
      name: 'Invoice & Invoice Review',
      value: 'invoice',
    },
    {
      name: 'Expense',
      value: 'expense',
    },
    {
      name: 'Commission',
      value: 'commission',
    },
    {
      name: 'Reconciliation',
      value: 'reconciliation',
    },
    {
      name: 'Tasks',
      value: 'task',
    },
    {
      name: 'Opportunity',
      value: 'opportunity',
    },
    {
      name: 'Business Entity',
      value: 'company',
    },
  ]

  public static action_list = [
    {
      name: 'View',
      value: 'view',
    },
    {
      name: 'Edit',
      value: 'edit',
    },
    {
      name: 'Delete',
      value: 'delete',
    },
  ]

  public module_listing: any = FaqModel.module_list

  public action_listing: any = FaqModel.action_list

  public static async query(query: any) {
    let api = new Api()
    return api
      .get('faqs/query', query)
      .then(res => res.data.result.map((r: any) => Object.assign(new FaqModel(), r)))
  }
}
