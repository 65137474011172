
import { randomUUID } from '@/models/interface/Common'
import { Component, Vue, Prop } from 'vue-property-decorator'
import custom_icons from '../CustomIcon/custom_icons'
import CustomIcon from '../CustomIcon/CustomIcon.vue'

@Component({
  components: {
    CustomIcon,
  },
})
export default class IconAction extends Vue {
  @Prop({ default: () => randomUUID() })
  public id!: string

  @Prop({ default: 'question' })
  public icon!: string

  @Prop({ default: false })
  public customIcon!: string | boolean

  @Prop({ default: '' })
  public title!: string

  @Prop({ default: 'primary' })
  public color!: string

  @Prop({ default: 'lg' })
  public size!: string

  @Prop({ default: '' })
  public label!: string

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: false })
  public loading!: boolean

  @Prop({ default: false })
  public lab!: boolean

  public get customIconData() {
    // @ts-ignore
    return this.custom_icons[this.customIcon]
  }

  public get customColor() {
    if (this.statusColor === 'secondary') {
      return '#798892'
    }
    if (this.statusColor === 'danger') {
      return '#f55d5d'
    }
    if (this.statusColor === 'success') {
      return '#3abf94'
    }
    if (this.statusColor === 'warning') {
      return '#ffc247'
    }
    return '#41b883'
  }

  public get custom_icons() {
    return custom_icons
  }

  public get base_class() {
    return this.lab !== false ? 'lab' : 'la'
  }

  public get statusColor() {
    return this.disabled !== false ? 'secondary' : this.color
  }

  public click() {
    if (this.disabled === false) this.$emit('click')
  }
}
