
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
// @ts-ignore
import { VueMaskDirective } from 'v-mask'

import InputLoader from '@/components/InputLoader/InputLoader.vue'

Vue.directive('mask', VueMaskDirective)

@Component({
  components: {
    InputLoader,
  },
})
export default class FormInput extends Vue {
  @Prop({ default: '' })
  public prepend!: string

  @Prop({ default: '' })
  public append!: string

  @Prop({ default: false })
  public is_loading!: any

  @Prop({ default: false })
  public plain!: any

  @Prop({ default: false })
  public noValidation!: any

  @Prop({ default: false })
  public noErrors!: any

  @Prop({ default: '' })
  public value!: string

  @Prop({ default: 'text' })
  public label_format!: string

  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: 0 })
  public debounce!: number

  @Prop({ default: false })
  public lazy!: boolean

  @Prop({ default: false })
  public vertical!: boolean

  @Prop({ default: '' })
  public name!: string

  @Prop({ default: '' })
  public mask!: any

  @Prop({ default: '' })
  public label!: string

  @Prop({ default: '' })
  public description!: string

  @Prop({ default: '' })
  public vid!: string

  @Prop({ default: '' })
  public icon!: string

  @Prop({ default: 'input' })
  public id!: string

  @Prop({ default: 'text' })
  public type!: string

  public update: boolean = true

  @Prop({ default: '' })
  public rules!: [Object, String]

  @Prop({ default: '' })
  public inputSuffix: any = ''

  @Prop({ default: 0 })
  public index!: number

  @Prop({ default: null })
  public setValidation!: any

  @Prop({ default: 0 })
  public width!: number

  @Watch('inputSuffix')
  public inputSuffixChange(val: any) {
    if (val !== null) {
      this.suffixPosition()
    }
  }

  public get updateSize() {
    if (!this.width) return ''
    return `
      width: ${this.width}px;
    `
  }

  public suffixPosition(time = 100) {
    let getTextWidth = (v: any, font: any) => {
      let canvas = document.createElement('canvas')
      let context: any = canvas.getContext('2d')
      context.font = font
      let metrics = context.measureText(v)
      return metrics.width
    }
    setTimeout(() => {
      let inputsContainer: any = document.getElementsByClassName(`suffix-${this.index}`)
      for (let element of inputsContainer) {
        let inputElement = element.getElementsByClassName('input')[0]
        let suffixElement = element.getElementsByClassName('suffix')
        let fontSize = window.getComputedStyle(inputElement).getPropertyValue('font-size')
        let fontFamily = window.getComputedStyle(inputElement).getPropertyValue('font-family')
        let width = getTextWidth(inputElement.value, `${fontSize} ${fontFamily} `)
        // @ts-ignore
        if (suffixElement[0]) {
          suffixElement[0].style.left = `${width + 15}px`
        }
      }
    }, time)
  }

  public secrect: string = ''

  public get uuid() {
    return `${this.id}-${this.secrect}`
  }

  public get formLabel() {
    return this.label ? this.label + (this.rules.includes('required') ? ' *' : '') : ''
  }

  public get local_value(): string {
    return this.value
  }

  public set local_value(val: string) {
    if (val && this.type === 'number' && typeof val === 'string') {
      val = val.replace(/[^0-9\.-]/g, '')
    }

    this.$emit('input', val)
  }

  public mounted() {
    this.secrect = String(Math.floor(Math.random() * Math.floor(999)))
  }

  public created() {
    this.local_value = this.value
  }
}
