// Defining callable handlers

// Hadler to determine if action should be visible or not
interface DataTableShowHandler {
  (row: any): boolean
}

// Action handler
interface DataTableActionHandler {
  (row: any, dataTable: any, container: any): void | Promise<any>
}

/**
 * Allows adding actions to the datatable
 */
export default class DataTableAction {
  // Create model instace
  constructor(
    title: string,
    event: string,
    icon: string = '',
    show: null | boolean | DataTableShowHandler = null,
    action: null | DataTableActionHandler = null,
  ) {
    this.title = title
    this.event = event
    this.icon = icon
    this.show = show
    this.action = action
  }

  // Action Icon
  public icon: string = ''

  // Action Title
  public title: string = ''

  // Event to name that will be emited on action click, must be unique
  public event: string = ''

  // Indicates if the task should be visible
  public show: null | boolean | DataTableShowHandler = null

  /**
   * Action handler
   * If provided, datatable won't emit the event on action click, instead it will execute the handler.
   * If the handler returns a promise, the datatable will refresh on resolve
   */
  public action: null | DataTableActionHandler = null
}
