export default {
  'filter-remove': {
    paths: [
      'M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z',
    ],
    style: '',
    fill_rule: 'evenodd',
    clip_rule: 'evenodd',
    height: 110.668,
    width: 122.88,
    x: 0,
    y: 0,
  },
  quickbooks: {
    paths: [
      'M 16 3 C 8.8321429 3 3 8.8321429 3 16 C 3 23.167857 8.8321429 29 16 29 C 23.167857 29 29 23.167857 29 16 C 29 8.8321429 23.167857 3 16 3 z M 16 5 C 22.086977 5 27 9.9130231 27 16 C 27 22.086977 22.086977 27 16 27 C 9.9130231 27 5 22.086977 5 16 C 5 9.9130231 9.9130231 5 16 5 z M 17 9 L 17 21 L 19 21 L 21 21 C 23.287457 21 25 18.896183 25 16.5 C 25 14.103817 23.287457 12 21 12 L 21 14 C 22.026543 14 23 15.030183 23 16.5 C 23 17.969817 22.026543 19 21 19 L 19 19 L 19 9 L 17 9 z M 11 11 C 8.7125434 11 7 13.103817 7 15.5 C 7 17.896183 8.7125434 20 11 20 L 11 18 C 9.9734566 18 9 16.969817 9 15.5 C 9 14.030183 9.9734566 13 11 13 L 13 13 L 13 23 L 15 23 L 15 11 L 14 11 L 13 11 L 11 11 z',
    ],
    height: 32,
    width: 32,
    x: 0,
    y: 0,
  },
  watch: {
    paths: [
      'M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26.13A12.13,12.13,0,1,1,28.13,16,12.14,12.14,0,0,1,16,28.13Z',
      'M16,6.9a.93.93,0,0,0-.93.93v7.24H9.47a.93.93,0,1,0,0,1.86H16a.92.92,0,0,0,.93-.93V7.83A.93.93,0,0,0,16,6.9Z',
    ],
    style: 'margin-top: -0.2em',
    fill_rule: 'nonzero',
    clip_rule: 'evenodd',
    height: 32,
    width: 32,
    x: 0,
    y: 0,
  },
  stopwatch: {
    paths: [
      'M18.3 8.59l.91-.9a1 1 0 0 0-1.42-1.42l-.9.91a8 8 0 0 0-9.79 0l-.91-.92a1 1 0 0 0-1.42 1.43l.92.91A7.92 7.92 0 0 0 4 13.5a8 8 0 1 0 14.3-4.91zM12 19.5a6 6 0 1 1 6-6a6 6 0 0 1-6 6zm-2-15h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zm3 6a1 1 0 0 0-2 0v1.89a1.5 1.5 0 1 0 2 0z',
    ],
    style: 'margin-top: -0.3em',
    fill_rule: 'evenodd',
    clip_rule: 'evenodd',
    strokeWidth: '0.15',
    height: 24,
    width: 24,
    x: 0,
    y: 0,
  },
  'stopwatch-slash': {
    paths: [
      'M10.6 5.63a1 1 0 0 0 .36 2a6.18 6.18 0 0 1 1-.09a6 6 0 0 1 6 6a6.18 6.18 0 0 1-.09 1a1 1 0 0 0 .8 1.16h.18a1 1 0 0 0 1-.82A7.45 7.45 0 0 0 20 13.5a8 8 0 0 0-1.7-4.91l.91-.9a1 1 0 0 0-1.42-1.42l-.9.91A8 8 0 0 0 12 5.5a7.45 7.45 0 0 0-1.4.13zM10 4.5h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zm3.49 9.08v-.08v-.08l1.34-1.33a1 1 0 1 0-1.42-1.42L12.08 12h-.16L5.71 5.79a1 1 0 0 0-1.42 1.42l.48.48l.91.91A8 8 0 0 0 16.9 19.82l1.39 1.39a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42zM12 19.5A6 6 0 0 1 7.11 10l3.4 3.39v.08A1.5 1.5 0 0 0 12 15h.08l3.39 3.4A6 6 0 0 1 12 19.5z',
    ],
    style: 'margin-top: -0.3em',
    fill_rule: 'evenodd',
    clip_rule: 'evenodd',
    strokeWidth: '0.15',
    height: 24,
    width: 24,
    x: 0,
    y: 0,
  },
  trade: {
    height: 24,
    width: 24,
    strokeWidth: '0.15',
    x: 0,
    y: 0,
    paths: [
      'M22,11H2A1,1,0,0,1,2,9H19.59l-2.3-2.29a1,1,0,0,1,1.42-1.42l4,4a1,1,0,0,1,.21,1.09A1,1,0,0,1,22,11Z',
      'M6,19a1,1,0,0,1-.71-.29l-4-4a1,1,0,0,1-.21-1.09A1,1,0,0,1,2,13H22a1,1,0,0,1,0,2H4.41l2.3,2.29a1,1,0,0,1,0,1.42A1,1,0,0,1,6,19Z',
    ],
  },
  gauge: {
    height: 32,
    width: 48,
    strokeWidth: '0.8',
    style: 'margin-top: -0.4em',
    x: 0,
    y: 0,
    fill_rule: 'evenodd',
    clip_rule: 'evenodd',
    paths: [
      'M44.9,32.971c0,0.011,0.006,0.019,0.006,0.029c0,0.005-0.003,0.01-0.003,0.016  c0.001,0.005,0.003,0.01,0.003,0.016c0,0.552-0.447,1-1,1C43.921,34.031,35,34,35,34c-0.553,0-1-0.448-1-1c0-0.553,0.447-1,1-1  h7.946C42.965,31.666,43,31.338,43,31c0-10.493-8.507-19-19-19S5,20.507,5,31c0,0.338,0.035,0.666,0.053,1H13c0.553,0,1,0.447,1,1  c0,0.552-0.447,1-1,1c0,0-8.92,0.031-8.906,0.031c-0.553,0-1-0.448-1-1c0-0.006,0.002-0.011,0.003-0.016  c0-0.006-0.003-0.011-0.003-0.016c0-0.011,0.006-0.019,0.006-0.028C3.039,32.321,3,31.665,3,31c0-11.599,9.402-21,21-21  s21,9.401,21,21C45,31.665,44.961,32.321,44.9,32.971z M17.523,21.128c0.393-0.216,0.865-0.144,1.189,0.133l-0.05-0.083  c8.774,6.42,10.577,9.373,10.61,9.428c1.433,2.357,0.581,5.371-1.899,6.731s-5.653,0.553-7.085-1.804  c-0.035-0.053-1.824-3.014-3.291-13.442l0.051,0.082C16.959,21.769,17.131,21.343,17.523,21.128z M22.086,34.549  c0.555,0.911,1.589,1.478,2.699,1.478c0.542,0,1.079-0.137,1.552-0.397c0.72-0.395,1.235-1.031,1.45-1.794  c0.216-0.764,0.105-1.56-0.303-2.232c-0.017-0.025-1.572-2.386-8.062-7.348C20.703,32.071,22.075,34.529,22.086,34.549z',
    ],
  },
  treemap: {
    height: 32,
    width: 32,
    strokeWidth: '0.01',
    x: 0,
    y: 0,
    style: 'margin-top: -0.1em',
    fill_rule: 'evenodd',
    clip_rule: 'evenodd',
    paths: [
      'M28,2H4A2.0023,2.0023,0,0,0,2,4V28a2.0023,2.0023,0,0,0,2,2H28a2.0023,2.0023,0,0,0,2-2V4A2.0023,2.0023,0,0,0,28,2Zm0,12H23V4h5ZM16,4h5V14H16ZM14,4V20H4V4ZM4,22H14v6H4Zm12,6V16H28V28Z',
    ],
  },
  radial: {
    height: 32,
    width: 32,
    strokeWidth: '0.01',
    x: 0,
    y: 0,
    fill_rule: 'none',
    style: 'margin-top: -0.25em',
    paths: [
      'M16,30A14.0158,14.0158,0,0,1,2,16H4A12,12,0,1,0,16,4V2a14,14,0,0,1,0,28Z',
      'M16,26A10.0115,10.0115,0,0,1,6,16H8a8,8,0,1,0,8-8V6a10,10,0,0,1,0,20Z',
      'M16,22a6.0069,6.0069,0,0,1-6-6h2a4,4,0,1,0,4-4V10a6,6,0,0,1,0,12Z',
    ],
  },
  radar: {
    height: 32,
    width: 32,
    strokeWidth: '0.01',
    x: 0,
    y: 0,
    fill_rule: 'none',
    style: 'margin-top: -0.25em',
    paths: [
      'M16,2,2,13,8,30H24l6-17Zm2.5818,19.2651-5.9861,1.3306-1.4226-7.8252,4.91-4.209,5.4416,4.0816Zm.1977,2.0054L21.3264,28H10.6736l1.7912-3.3267ZM9.59,13.4937,5.74,12.605,15,5.3291V8.8569ZM17,8.75V5.3291l9.26,7.2759-3.15.727ZM4.6143,14.3979l4.6535,1.0738,1.4844,8.164-1.738,3.2281ZM22.9858,26.8638l-2.5766-4.7852,3.0063-6.7646,3.97-.9161Z',
    ],
  },
}
