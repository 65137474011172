export default class FeatureAccess {
  public model!: string

  public action!: string

  public access: boolean = false

  public options: string[] = []

  constructor(model: string, action: string, access: boolean = false, options: string[] = []) {
    this.model = model
    this.action = action
    this.access = access
    this.options = options
  }
}
