import { Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import store from '@/store'
import LayoutModule from '@/store/LayoutModule'
import SystemtModule from '@/store/SystemModule'
import router from '@/Routes'
import User from './User'

export default class ViewModel extends Vue {
  public layout: LayoutModule = getModule(LayoutModule)

  public system: SystemtModule = getModule(SystemtModule)

  public loading: boolean = false

  public cdnPath = process.env.VUE_APP_CDN_PATH

  public rootCompanyId = process.env.VUE_APP_ROOT_COMPANY_ID

  public get user(): User {
    // @ts-ignore
    // console.log('user', this.system.user)

    // @ts-ignore
    return this.system.user
  }

  public get is_quickbooks_connected(): boolean {
    return this.system.is_quickbooks_connected
  }

  public get instance_id(): string {
    return this.system._uuid
  }

  public back() {
    router.back()
  }

  public randomUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0
      const v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }
}
