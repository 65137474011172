/** @format */

// System
import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import Vue from 'vue'
import OneSignalVue from 'onesignal-vue'
import PortalVue from 'portal-vue'

// Bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// @ts-ignore
import Ripple from 'vue-ripple-directive'

// Vee Validate
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required,
  email,
  min,
  max,
  numeric,
  between,
  image,
  min_value,
  max_value,
} from 'vee-validate/dist/rules'

import './filters'

// @ts-ignore
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import Vue2Filters from 'vue2-filters'

import './registerServiceWorker'
import Snotify from 'vue-snotify'
// @ts-ignore
import VueEcho from 'vue-echo'

// Recaptcha
import { VueReCaptcha } from 'vue-recaptcha-v3'

import LayoutModule from '@/store/LayoutModule'
import SystemModule from '@/store/SystemModule'
import isIP from 'validator/lib/isIP'
import isUUID from 'validator/lib/isUUID'
import isURL from 'validator/lib/isURL'
import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue'
import VueAxios from './plugins/axios'
import util from './mixins/util'
import App from './App.vue'
import layoutMixin from './mixins/layout'
import router from './Routes'

import './styles/theme.scss'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.component('SkeletonLoader', SkeletonLoader)

Vue.use(OneSignalVue)

Vue.directive('ripple', Ripple)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
extend('email', {
  ...email,
  message: 'The {_field_} field must be a valid email',
})
extend('required', { ...required, message: 'The {_field_} field is required' })
extend('numeric', {
  ...numeric,
  message: 'The {_field_} field may only contain numeric characters',
})
extend('min', {
  ...min,
  message: 'The {_field_} field must be at least {length} characters',
})
extend('max', {
  ...max,
  message: 'The {_field_} field may not be greater than {length} characters',
})
extend('min_value', {
  ...min_value,
  message: 'The {_field_} field must be at least {min}',
})
extend('max_value', {
  ...max_value,
  message: 'The {_field_} field may not be greater than {max}',
})
extend('between', {
  ...between,
  message: 'The {_field_} field must be between {min} and {max}',
})
extend('image', { ...image, message: 'The {_field_} field must be an image' })
const isNullOrUndefined = (...values: any): boolean =>
  values.every((value: any) => value === null || value === undefined)
extend('ip', {
  validate: value => {
    if (isNullOrUndefined(value)) {
      value = ''
    }

    if (Array.isArray(value)) {
      return value.every(val => isIP(val, 4) || isIP(val, 6))
    }

    return isIP(value, 4) || isIP(value, 6)
  },
  message: 'The {_field_} field must be a valid ip v4 or v6',
})
extend('uuid', {
  validate: value => {
    if (isNullOrUndefined(value)) {
      value = ''
    }

    if (Array.isArray(value)) {
      return value.every(val => isUUID(val))
    }

    return isUUID(value)
  },
  message: 'The {_field_} field must be a valid uuid',
})
extend('is', {
  params: ['target'],
  validate: (value, confirm: any) => value === confirm.target,
  message: 'The {_field_} field must match the {target}',
})
extend('is_not', {
  params: ['target'],
  validate: (value, confirm: any) => value !== confirm.target,
  message: 'The {_field_} field must NOT match the {target}',
})
extend('https', {
  validate: value => isURL(value, { protocols: ['https'] }),
  message: 'The {_field_} field must be a valid https url',
})
extend('excel', {
  validate: (files: File | File[]) => {
    const regex = /\.(xlsx|xls)$/i
    if (Array.isArray(files)) {
      return files.every(file => regex.test(file.name))
    }

    return regex.test(files.name)
  },
  message: 'The {_field_} field must be an excel or csv file',
})
Vue.use(vueNumeralFilterInstaller, { locale: 'en-au' })
Vue.use(Vue2Filters)

Vue.use(Snotify, {
  toast: {
    timeout: process.env.VUE_APP_NOTIFY_TIMEOUT || 5000,
  },
})
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_NOCAPTCHA_SITEKEY,
  loaderOptions: {
    autoHideBadge: true,
  },
})

// @ts-ignore
window.Pusher = require('pusher-js')

if (process.env.VUE_APP_BROADCAST_DRIVER == 'ably') {
  // ACtivate Ably
  Vue.use(VueEcho, {
    broadcaster: 'pusher',
    key: process.env.VUE_APP_ABLY_PUBLIC_KEY,
    wsHost: 'realtime-pusher.ably.io',
    authEndpoint: `${process.env.VUE_APP_BASE_API_URL}broadcasting/auth`,
    wsPort: 443,
    disableStats: true,
    encrypted: true,
    forceTLS: true,
    auth: {
      headers: { Authorization: '' },
    },
  })
} else {
  // Fallback driver
  Vue.use(VueEcho, {
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHERKEY,
    cluster: 'mt1',
    forceTLS: true,
    authEndpoint: `${process.env.VUE_APP_BASE_API_URL}broadcasting/auth`,
    auth: {
      headers: { Authorization: '' },
    },
  })
}
Vue.use(require('vue-moment'))

Vue.use(VueAxios)

/* Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg',
    },
}); */

Vue.mixin(layoutMixin)
Vue.mixin(util)

Vue.use(PortalVue)

Vue.config.productionTip = false

if (!store.hasModule('layout')) {
  store.registerModule('layout', LayoutModule)
}

if (!store.hasModule('system')) {
  store.registerModule('system', SystemModule)
}

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  store,
  router,
  beforeMount() {
    this.$OneSignal.init({
      appId: 'ca0c9ea2-2f51-4e85-99c0-c2d2ab47b81e',
      safari_web_id: '',
      notifyButton: {
        enable: false,
      },
    })
  },
  async created() {
    let token = ''
    if (process.env.NODE_ENV !== 'development') {
      // @ts-ignore
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      // @ts-ignore
      token = await this.$recaptcha('login')
    }

    const system = getModule(SystemModule, store)
    system.init(token)
  },
  render: h => h(App),
})
