
import { Component, Vue, Watch } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Api from '@/models/Api'
import { getModule } from 'vuex-module-decorators'
import WebMessage from '../../models/WebMessage'
import User from '../../models/User'
import SystemtModule from '../../store/SystemModule'

@Component({
  components: {
    Widget,
  },
})
export default class LoginPage extends Vue {
  public errorMessage: string | null = null

  public processing = false

  public remember = false

  public trust_device = false

  public device_name = ''

  public step: string = 'login'

  public account = null

  public verification = null

  public username: string = ''

  public password: string = ''

  public token: null | string = null

  public key: string = ''

  public get system_loading(): boolean {
    return getModule(SystemtModule).loading.system
  }

  public get app_env(): string {
    return getModule(SystemtModule).enviroment
  }

  public get device_uuid(): string {
    return getModule(SystemtModule).device_uuid
  }

  public get cb_mfa_token(): string {
    return getModule(SystemtModule).mfa_token
  }

  public get login_error(): string {
    return getModule(SystemtModule).login_error
  }

  @Watch('cb_mfa_token')
  public onCbMfaTokenChange(newValue: string) {
    if (newValue) {
      this.token = newValue
      this.step = 'mfa'
    }
  }

  @Watch('login_error')
  public onLoginErrorChange(newValue: string) {
    if (newValue) {
      this.errorMessage = newValue
    }
  }

  public async login() {
    this.processing = true
    this.errorMessage = ''
    let token = ''
    if (process.env.NODE_ENV !== 'development') {
      // @ts-ignore
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      // @ts-ignore
      token = await this.$recaptcha('login')
    }

    const api = new Api(false)
    api
      .post('user/login', {
        email: this.username,
        password: this.password,
        remember: this.remember,
        device_uuid: this.device_uuid,
        token,
      })
      .then(response => {
        if (response.data.message === 'pending_mfa') {
          this.step = 'mfa'
          this.token = response.data.result.token
          this.processing = false
          return
        }
        // Parse and Store User object
        const user = User.toObject(response.data.result.user)

        const system = getModule(SystemtModule)

        system.updateState({
          name: 'user',
          data: user,
        })

        if (user.isSuperAdmin) {
          localStorage.setItem('is_root_company', 'true')
        } else {
          localStorage.removeItem('is_root_company')
        }

        this.$store.commit('system/updateCSRF', response.data.result.csrf)

        // Store api token
        if (response.data.result.api_token) {
          this.$store.commit('system/updateApiToken', response.data.result.api_token)
        }

        if (response.data.result.token) {
          localStorage.setItem(response.data.result.token.name, response.data.result.token.value)
        } else {
          localStorage.removeItem('remember_token')
        }

        this.$store.dispatch('system/sync')
        this.processing = false
      })
      .catch(e => {
        if (e.response && e.response.status === 429) {
          this.errorMessage = e.response.data.errors.email[0]
        } else this.errorMessage = 'Invalid Username or password'
        this.processing = false
      })
  }

  public confirmMFA() {
    this.processing = true
    this.errorMessage = ''
    const api = new Api()
    api
      .post('user/login/mfa', {
        token: this.token,
        key: this.key,
        trust_device: this.trust_device,
        device_uuid: this.device_uuid,
        device_name: this.device_name,
      })
      .then(response => {
        // Parse and Store User object
        const user = User.toObject(response.data.result.user)

        const system = getModule(SystemtModule)

        system.updateState({
          name: 'user',
          data: user,
        })

        this.$store.commit('system/updateCSRF', response.data.result.csrf)

        // Store api token
        if (response.data.result.api_token) {
          this.$store.commit('system/updateApiToken', response.data.result.api_token)
        }

        if (response.data.result.token) {
          localStorage.setItem(response.data.result.token.name, response.data.result.token.value)
        } else {
          localStorage.removeItem('remember_token')
        }

        this.$store.dispatch('system/sync').then(() => {
          if (response.data.result.backup_code_used) {
            setTimeout(() => {
              WebMessage.warning(
                `You used a recovery code to login, you have ${response.data.result.backup_codes_remaining} codes remaining!`,
              )
            }, 2000)
          }
        })
        this.processing = false
      })
      .catch(e => {
        if (e.response && e.response.status === 429) {
          this.errorMessage = e.response.data.errors.email[0]
        } else this.errorMessage = 'Invalid MFA Code'
        this.processing = false
      })
  }

  public forgotPassword() {
    this.step = 'reset'
  }

  public resetPassword() {
    const api = new Api()
    api
      .post('password', {
        email: this.account,
      })
      .then(() => {
        this.step = 'validate'
        WebMessage.success('Please confirm the validation code sent to your email address.')
      })
      .catch(() => {
        WebMessage.error('Invalid username! Please confirm the provided information.')
      })
  }

  public validatePassword() {
    const api = new Api()
    api
      .patch('password', {
        email: this.account,
        verification: this.verification,
      })
      .then(() => {
        this.step = 'login'
        WebMessage.success('Your new password was sent to your email.')
      })
      .catch(() => {
        this.verification = null
        WebMessage.error('Invalid validation code! A new code was sent to your email')
      })
  }

  public cancel() {
    this.step = 'login'
  }

  public created() {
    if (this.cb_mfa_token) {
      this.token = this.cb_mfa_token
      this.step = 'mfa'
    }

    try {
      // @ts-ignore
      this.device_name = `${window.navigator.userAgentData.platform}`
      this.device_name
        // @ts-ignore
        += ` - ${
          window.navigator.userAgentData.brands[window.navigator.userAgentData.brands.length - 1]
            .brand
        }` // @ts-ignore
    } catch (e) {
      // do nothing
    }
  }
}
