import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import router from '@/Routes'
import DataTableField from '@/components/DataTable/DataTableField'
import DataTableAction from '@/components/DataTable/DataTableAction'
import { startCase } from 'lodash'
import DataTableDateField from '@/components/DataTable/DataTableDateField'
import DataTableActionField from '@/components/DataTable/DataTableActionField'
import User from '../User'
import WebMessage from '../WebMessage'
import SelectOption from '../interface/SelectOption'

export const model_name = 'user'

export const tableFields: Array<any> = [
  new DataTableField('name', 'Name'),
  new DataTableField('title', 'Title'),
  new DataTableField('email', 'Email'),
  new DataTableField('phone', 'Phone', 'text', false, false),
  new DataTableField(
    'department',
    'Department',
    'capitalize',
    false,
    true,
    'align-middle text-center',
    null,
    value => (value ? startCase(value.replaceAll('_', ' ')) : 'N/A'),
  ),
  new DataTableField('type', 'Type', 'capitalize', false),
  new DataTableField('company', 'Company', 'object', true, true, 'align-middle text-center', {
    key: 'company',
    property: 'name',
  }),
  new DataTableField('station', 'Station', 'object', true, true, 'align-middle text-center', {
    key: 'station',
    property: 'name',
  }),
  new DataTableField('profile', 'Profile', 'object', true, true, 'align-middle text-center', {
    key: 'profile',
    property: 'name',
  }),
  new DataTableField('status', 'Status'),

  {
    key: 'mfa_enabled',
    label: 'MFA Enabled',
    thClass: 'align-middle text-center',
    sortable: false,
    show: false,
    type: 'badge',
    color: (user: any) => (user.mfa_enabled ? 'success' : 'warning'),
    tdClass: 'align-middle text-center',
    formatter: (value: string | null) => (value ? 'Yes' : 'No'),
    // @ts-ignore
    filter_type: 'picker',
    options: [
      new SelectOption('MFA Enabled', ''),
      new SelectOption('Yes', '1'),
      new SelectOption('No', '0'),
    ],
  },
  {
    key: 'created_at',
    label: 'Created At',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
    filter_type: 'date',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
    filter_type: 'date',
  },
  {
    key: 'login_at',
    label: 'Last Login',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
    filter_type: 'date',
  },
  new DataTableActionField([
    new DataTableAction(
      'Edit User',
      'edit-user',
      'pencil',
      () => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('user', 'edit')) as boolean
      },
      (user: User) => {
        let ref = window.location.href.includes('legacy-dashboard') ? '?ref=LegacyDashboard' : ''
        router.push(`/app/user/${user.id}${ref}`)
      },
    ),
    new DataTableAction(
      'Reset Password',
      'password-reset',
      'lock',
      () => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('user', 'edit') && user.isSuperAdmin) as boolean
      },
      (target: User) =>
        WebMessage.confirm(
          `Are you sure that you want to reset the user's "<strong>${target.name}<${target.email}}></strong>" password?`,
          'Reset Password',
          { okTitle: 'Reset Password' },
        ).then((value: boolean) => {
          if (value) {
            return target.resetPassword()
          }
        }),
    ),
    new DataTableAction(
      'Delete User',
      'delete-user',
      'trash',
      () => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('user', 'delete')) as boolean
      },
      (target: User) =>
        WebMessage.confirm(
          `Are you sure that you want to delete the user "<strong>${target.name}<${target.email}}></strong>"? You won't be able to restore it!`,
          'Delete User',
        ).then((value: boolean) => {
          if (value) {
            return target.delete()
          }
        }),
    ),

    new DataTableAction(
      'Disable User',
      'lock-user',
      'las la-user-lock',
      (target: User) => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('user', 'edit') && target.status === 'active') as boolean
      },
      (target: User) =>
        WebMessage.confirm(
          `Are you sure that you want to deactivate the user "<strong>${target.name}<${target.email}}></strong>"? You can change it back anytime.!`,
          'Disable User',
          {
            okTitle: 'Yes',
            cancelTitle: 'Cancel',
          },
        ).then((value: boolean) => {
          if (value) {
            return target.lockUser({ date: null, status: 'disabled' })
          }
        }),
    ),

    new DataTableAction(
      'Activate User',
      'unlock-user',
      'las la-user-lock',
      (target: User) => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('user', 'edit') && target.status !== 'active') as boolean
      },
      (target: User) =>
        WebMessage.confirm(
          `Are you sure that you want to activate the user "<strong>${target.name}<${target.email}}></strong>"? You can change it back anytime.!`,
          'Activate User',
          {
            okTitle: 'Yes',
            cancelTitle: 'Cancel',
          },
        ).then((value: boolean) => {
          if (value) {
            return target.lockUser({ date: null, enable: true })
          }
        }),
    ),
  ]),
]
