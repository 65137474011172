/** @format */

export default class SelectOption {
  name: string = ''

  text: string = ''

  value: string | number | null | boolean = null

  description: string = ''

  owner_id: string | null = null

  type: string | number | null = null

  detail: string | null = null

  full_name: string | null = null

  constructor(
    name: string,
    value: string | number | null | boolean = null,
    owner_id: string | null = null,
    type: string | null = null,
    detail: string | null = null,
    full_name: string | null = null,
  ) {
    this.name = name
    this.text = name
    this.value = value
    this.owner_id = owner_id
    this.type = type
    this.detail = detail
    this.full_name = full_name
  }

  public static toObject(data: any): SelectOption {
    const o = new SelectOption(
      data.name,
      data.value,
      data.owner_id ?? null,
      data.type ?? null,
      data.detail || data.email ? data.detail ?? data.email : null,
      data.full_name ?? null,
    )

    if (data.owner_id) o.owner_id = data.owner_id
    if (data.type) o.type = data.type

    return o
  }

  public static toObjectList(data: any): SelectOption[] {
    const options = new Array<SelectOption>()
    data.forEach((value: any) => {
      const o = SelectOption.toObject(value)
      options.push(o)
    })
    return options
  }

  public static createOptionWithDescription(
    name: string,
    value: string | number | null | boolean,
    description: string,
  ): SelectOption {
    let o = new SelectOption(name, value)
    o.description = description
    return o
  }

  public static createOptionsWithDescription(param: Array<any>): SelectOption[] {
    return param.map((value: any) =>
      SelectOption.createOptionWithDescription(value.name, value.value, value.description))
  }
}
