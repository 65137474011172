var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.edit)?_c('ValidationProvider',{ref:"validationProvider",attrs:{"vid":_vm.vid,"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ validated, valid, errors }){return [_c('b-form-group',_vm._b({attrs:{"id":_vm.id,"horizontal":_vm.vertical === false,"label-cols":_vm.vertical === false ? 3 : null,"label-breakpoint":"md","label-for":_vm.actionEdit !== false || true ? _vm.uuid + 'x' : _vm.uuid,"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.$slots.label)?_c('div',{attrs:{"id":`${_vm.name}_label`}},[_vm._t("label")],2):_vm._e(),(!_vm.$slots.label)?_c('span',{class:{ 'has-tooltip': _vm.has_description },attrs:{"id":`${_vm.name}_label`}},[_vm._v(" "+_vm._s(_vm.formLabel)+" ")]):_vm._e(),(_vm.has_description)?_c('b-tooltip',{attrs:{"custom-class":"long-tooltip","target":`${_vm.name}_label`}},[_c('div',{staticClass:"text-left pt-2 px-2"},[_vm._v(" Available options: "),_c('ul',{staticStyle:{"list-style-type":"circle","margin-left":"2rem"}},_vm._l((_vm.options),function(o){return _c('li',{key:o.value},[_c('strong',[_vm._v(_vm._s(o.name)+":")]),_vm._v(" "+_vm._s(o.description)+" ")])}),0)])]):_vm._e(),(!_vm.$slots.label)?_c('span',[_vm._t("label",null,null,{ name: _vm.name }),(_vm.actionEdit !== false)?_c('IconAction',{attrs:{"icon":"pencil"},on:{"click":function($event){return _vm.$emit('edit')}}}):_vm._e()],2):_vm._e()]},proxy:true},{key:"description",fn:function(){return [_vm._t("description")]},proxy:true}],null,true)},'b-form-group',_vm.$attrs,false),[(_vm.ready)?_c('multiselect',_vm._b({class:{
          'is-invalid': validated && !valid,
          'is-valid': validated && valid,
        },attrs:{"id":_vm.uuid,"name":_vm.uuid,"options":_vm.options,"placeholder":_vm.placeholder,"label":_vm.label_field,"track-by":_vm.label_field,"close-on-select":_vm.close_on_select,"searchable":true,"multiple":_vm.multiple !== false,"hide-selected":_vm.hide_selected,"taggable":_vm.taggable,"disabled":_vm.disabled,"allow-empty":_vm.allow_empty !== false,"internal-search":_vm.internal_search,"loading":_vm.busy,"deselect-label":"","group-values":_vm.groupValues,"group-label":_vm.groupLabel,"group-select":_vm.groupSelect},on:{"tag":_vm.addTag,"search-change":_vm.searchChange,"open":_vm.open,"close":function($event){return _vm.$emit('closed')},"select":(e) => {
            _vm.$emit('selected', e)
            _vm.$emit('changed', e)
          },"remove":(e) => _vm.$emit('removed', e)},model:{value:(_vm.local_value),callback:function ($$v) {_vm.local_value=$$v},expression:"local_value"}},'multiselect',_vm.$attrs,false)):_c('SkeletonLoader',{attrs:{"height":"80%"}})],1)]}}],null,false,1494817363)}):_c('b-form-group',{staticClass:"col-form-label",attrs:{"horizontal":_vm.vertical === false,"label-cols":_vm.vertical === false ? 3 : null,"label":_vm.label,"label-breakpoint":"md"}},[_c('span',[_vm._v(_vm._s(_vm.label_value))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }