import axios_http from 'axios'

// insert all your axios logic here

export const axios = axios_http

export default {
  install(Vue: any) {
    Vue.prototype.$axios = axios_http
    const token = document.head.querySelector('meta[name="csrf-token"]')

    if (token) {
      // @ts-ignore
      Vue.prototype.$axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
    } else {
      // console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
    }
  },
}
