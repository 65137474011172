
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class SkeletonLoader extends Vue {
  @Prop({ default: 1 })
  public count!: number

  @Prop({ default: 1.5 })
  public duration!: number

  @Prop({ default: 'span' })
  public tag!: string

  @Prop()
  public width!: string

  @Prop()
  public height!: string

  @Prop({ default: false })
  public circle!: boolean

  @Prop()
  public loading!: boolean

  @Prop()
  public color!: string

  DEFAULT_BACKGROUND = '#f9fbfd'

  DEFAULT_HIGHLIGHT = '#e6eef7'

  DEFAULT_DURATION = 1.5

  private themeStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '3px',
    animation: `SkeletonLoader ${this.DEFAULT_DURATION}s ease-in-out infinite`,
    backgroundColor: this.DEFAULT_BACKGROUND,
    backgroundImage: `linear-gradient(
    90deg,
    ${this.DEFAULT_BACKGROUND},
    ${this.DEFAULT_HIGHLIGHT},
    ${this.DEFAULT_BACKGROUND}
  )`,
  }

  private isEmptyVNode(children:any) {
    if (!children) return true

    const [firstNode] = children
    let str = firstNode.text
    if (str) {
      // remove all line-break and space character
      str = str.replace(/(\n|\r\n|\s)/g, '')
    }

    return typeof firstNode.tag === 'undefined' && !str
  }

  public render(h: any) {
    const {
      width, height, duration, circle, count, tag, loading,
    } = this
    const classes = 'skeleton-loader'
    const elements = []
    const styles = { ...this.themeStyle }

    if (duration) {
      styles.animation = `SkeletonLoader ${duration}s ease-in-out infinite`
    } else {
      styles.backgroundImage = ''
    }
    if (width) styles.width = width
    if (height) styles.height = height
    if (circle) styles.borderRadius = '50%'

    for (let i = 0; i < count; i += 1) {
      elements.push(
        <span key={i} class={classes} style={styles}>
          &zwnj;
        </span>,
      )
    }

    const showLoading = typeof loading !== 'undefined' ? loading : this.isEmptyVNode(this.$slots.default)
    if (tag) {
      return h(tag, !showLoading ? this.$slots.default : elements)
    }
    return !showLoading ? this.$slots.default : <span>{elements}</span>
  }
}
