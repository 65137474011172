/** @format */

import ApiParameters from '@/models/interface/ApiParameters'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
// @ts-ignore
import * as FileSaver from 'file-saver'
import axios, { AxiosRequestConfig } from 'axios'
import store from '../store'
import WebMessage from './WebMessage'

export default class Api {
  private basePath: string = process.env.VUE_APP_BASE_API_URL || '/'

  private config: any = {
    timeout: 480000,
    headers: {},
  }

  public catch_error = true

  private is_retrying = false

  constructor(catch_error: boolean = true, config: any = null) {
    const system = getModule(SystemtModule)

    if (config) {
      for (const key in config) {
        if (Object.prototype.hasOwnProperty.call(config, key)) {
          this.config[key] = config[key]
        }
      }
    }

    if (system.user && system.api_token) {
      this.config.headers.Authorization = `Bearer ${system.api_token}`
    }

    const instance_id = system._uuid

    if (instance_id) {
      this.config.headers['X-Instance-Id'] = instance_id
    }

    this.catch_error = catch_error
  }

  public get(path: string, data: ApiParameters = {}, catch_response: boolean = true) {
    const c = this.config
    c.params = data

    if (this.is_retrying) {
      this.is_retrying = false
      c.headers['X-Retry'] = 'true'
    }

    return axios.get(this.basePath + path, c).catch((error: any) => {
      if (catch_response && this.catch_error) {
        this.onError(error, () => this.get(path, data, catch_response))
      }
      throw error
    })
  }

  public post(path: string, data: ApiParameters = {}, catch_response: boolean = true) {
    const c = this.config

    if (this.is_retrying) {
      this.is_retrying = false
      c.headers['X-Retry'] = 'true'
    }

    return axios.post(this.basePath + path, data, c).catch((error: any) => {
      if (catch_response && this.catch_error) {
        return this.onError(error, () => this.post(path, data, catch_response))
      }
      throw error
    })
  }

  public patch(path: string, data: ApiParameters = {}, catch_response: boolean = true) {
    const c = this.config

    if (this.is_retrying) {
      this.is_retrying = false
      c.headers['X-Retry'] = 'true'
    }

    return axios.patch(this.basePath + path, data, c).catch((error: any) => {
      if (catch_response && this.catch_error) {
        return this.onError(error, () => this.patch(path, data, catch_response))
      }
      throw error
    })
  }

  public delete(path: string, data: ApiParameters = {}, catch_response: boolean = true) {
    const c = this.config
    c.params = data

    if (this.is_retrying) {
      this.is_retrying = false
      c.headers['X-Retry'] = 'true'
    }

    return axios.delete(this.basePath + path, c).catch((error: any) => {
      if (catch_response && this.catch_error) {
        return this.onError(error, () => this.delete(path, data, catch_response))
      }
      throw error
    })
  }

  public put(path: string, data: ApiParameters = {}, catch_response: boolean = true) {
    const c = this.config

    if (this.is_retrying) {
      this.is_retrying = false
      c.headers['X-Retry'] = 'true'
    }

    return axios.put(this.basePath + path, data, c).catch((error: any) => {
      if (catch_response && this.catch_error) {
        return this.onError(error, () => this.put(path, data, catch_response))
      }
      throw error
    })
  }

  public form(path: string, data: ApiParameters | FormData = {}, catch_response: boolean = true) {
    this.config.headers['Content-Type'] = 'multipart/form-data'
    this.config.timeout = 0
    let formData = null

    const c = this.config

    if (this.is_retrying) {
      this.is_retrying = false
      c.headers['X-Retry'] = 'true'
    }

    if (!(data instanceof FormData)) {
      formData = new FormData()

      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          formData.append(key, data[key])
        }
      }
    } else {
      formData = data
    }

    return axios.post(this.basePath + path, formData, c).catch((error: any) => {
      if (catch_response && this.catch_error) {
        return this.onError(error, () => this.form(path, data, catch_response))
      }
      throw error
    })
  }

  public setHeader(headers: any) {
    this.config.headers = headers
  }

  private onError(error: any, retry: any = null) {
    let message

    if (error.response && error.response.status == 423) {
      return WebMessage.doubleConfirm(
        'The model you are trying to edit was updated by another user, do you want to save anyway? This will overwrite the changes made by the other user.',
        'Model out of sync!',
        'Yes, Save anyway',
        { okTitle: 'Save' },
      ).then((result: boolean) => {
        if (result && retry !== null) {
          this.is_retrying = true
          return retry()
        }
      })
    }

    if (error.response && error.response.status == 422) {
      for (const key in error.response.data.errors) {
        message = error.response.data.errors[key][0]
        message = message.replace('targetting', 'targeting')
        break
      }
    }

    if (!message && error.response && error.response.status == 503) {
      message = 'System maintenance in progress, please try again later.'
    }

    if (
      !message
      && error.response
      && (error.response.status == 403 || error.response.status == 401)
    ) {
      store.dispatch('system/logout')
      message = "Your session expired or you don't have the required permissions to access this resource, please login again."
    }

    if (!message && !error.response) {
      message = 'We were not able to process your request, please check your internet connection and try again later.'
    }

    if (!message) {
      message = 'We were not able to process your request, please try again later.'
    }

    if (message) {
      WebMessage.error(message)
    }

    throw error
  }

  public download(path: string, output_name: string) {
    const system = getModule(SystemtModule)

    const op = path.includes('?') ? '&' : '?'

    FileSaver.saveAs(
      `${process.env.VUE_APP_BASE_API_URL + path}${op}api_token=${system.api_token}`,
      output_name,
    )
  }

  public getFileUrl(path: string) {
    const system = getModule(SystemtModule)

    const op = path.includes('?') ? '&' : '?'

    return `${process.env.VUE_APP_BASE_API_URL + path}${op}api_token=${system.api_token}`
  }
}
