import Model from './interface/Model'

export default class TrustedDevice extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'trusted_device' as string | null,
      plural: 'trusted_devices' as string | null,
    },
  }

  public user_id: string = ''

  public device_id: string = ''

  public device_name: string = ''

  public created_at: string = ''

  public updated_at: string = ''

  public last_used_at: string = ''
}
