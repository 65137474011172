/** @format */

import store from '@/store'
import Company from '@/models/Company'
import { getModule } from 'vuex-module-decorators'
import UserModule from '@/store/model/UserModule'
import ApiParameters from '@/models/interface/ApiParameters'
import moment from 'moment'
import SystemtModule from '@/store/SystemModule'
import Api from './Api'
import Profile from './Profile'
import WebMessage from './WebMessage'
import PaginateOptions from './interface/PaginateOptions'
import SelectOption from './interface/SelectOption'
import { tableFields } from './metadata/UserMetadata'
import TrustedDevice from './TrustedDevice'
import Model from './interface/Model'

export default class User extends Model {
  public api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'user' as string | null,
      plural: 'users' as string | null,
    },
  }

  public api_token: string | null = null

  public company_id: string | null = null

  public created_at: string | null = null

  public email: string = ''

  public phone: string = ''

  public email_verified_at: string | null = null

  public id: string | null = null

  public login_at: string | null = null

  public name: string = ''

  public title: string | null = null

  public photo: string | null = null

  public type: string = 'default'

  public profile_id: string | null = null

  public station_id: string | null = null

  public updated_at: string | null = null

  public home_page: string | null = null

  public report_view_id: string | null = null

  public oauth_accounts: string[] = []

  public metadata: any = {
    group_users: [],
  }

  public department: string | null = null

  public web_oauth: any = {}

  public mfa_enabled: boolean = false

  public mfa_required_at: any = null

  public trusted_devices: TrustedDevice[] = []

  public status: string = 'active'

  public expire_at: string | null = null

  public is_active: boolean = true

  public region: any = null

  public manager_id: any = null

  public get home(): string | null {
    if (
      this.company_id == '8e81bb0e-596b-451d-8126-7c5e2da3737a'
      || this.company_id == '8f8476aa-1d19-4ce0-8595-4f1c7683ae09'
    ) {
      return 'ForecastHome'
    }
    if (this.home_page) return this.home_page
    return this.company!.home_page
  }

  public toObject(source: any) {
    let instance = this.clone()

    if (typeof source.mfa_enabled !== 'undefined') instance.mfa_enabled = source.mfa_enabled
    instance.web_oauth = source.web_oauth

    if (source.company) {
      instance.company = Company.toObject(source.company)
    }

    if (source.station) {
      instance.station = Company.toObject(source.station)
    }

    if (source.profile) {
      instance.profile = Profile.toObject(source.profile)
    }

    if (source.trusted_devices) {
      instance.trusted_devices = TrustedDevice.toObjectList(source.trusted_devices)
    }

    if (source.metadata) {
      instance.metadata = {
        ...instance.metadata,
        ...source.metadata,
      }
    }

    // Remove dynamic data
    // delete source.mfa_enabled
    if (source.company !== undefined) delete source.company
    if (source.station !== undefined) delete source.station
    if (source.profile !== undefined) delete source.profile
    if (source.trusted_devices !== undefined) delete source.trusted_devices

    Object.assign(instance, source)

    return instance
  }

  public profile: Profile = new Profile()

  public company: Company = new Company()

  public station: Company = new Company()

  public get first_name() {
    return this.name.split(' ')[0]
  }

  public get last_name() {
    const name = this.name.split(' ')
    return name[name.length - 1]
  }

  public get company_logo() {
    if (this.station && this.station.getLogo()) return this.station.getLogo()
    if (this.company) return this.company.getLogo()

    return null
  }

  public get company_logo_white() {
    if (this.station && this.station.getLogoWhite()) return this.station.getLogoWhite()
    if (this.company) return this.company.getLogoWhite()
    return null
  }

  public get company_icon() {
    if (this.station && this.station.getIcon()) return this.station.getIcon()
    if (this.company) return this.company.getIcon()
    return null
  }

  public typeIn(list: string[]): boolean {
    return list.includes(this.type)
  }

  public get isRoot(): boolean {
    return this.type == 'root'
  }

  public get isSuperAdmin(): boolean {
    if (this.company) return this.company.root

    return false
  }

  public get isAdmin() {
    return this.can('user')
  }

  public get isStation(): boolean {
    return this.station_id != null
  }

  public get isSystem(): boolean {
    return this.type == 'system'
  }

  public can(model: string, action: string = 'any', company_level: boolean = false) {
    if (this.profile) return this.profile.can(model, action, company_level)
    return false
  }

  public canOptions(model: string, action: string = 'any') {
    if (this.profile) return this.profile.canOptions(model, action)
    return false
  }

  public get apiData() {
    const data: ApiParameters = {
      name: this.name as string,
      title: this.title as string,
      email: this.email as string,
      phone: this.phone as string,
      company_id: this.company_id as string,
      type: this.type as string,
      profile_id: this.profile_id as string,
      station_id: this.station_id as string | null,
      report_view_id: this.report_view_id as string | null,
      department: this.department as string | null,
      status: this.status as string,
      expire_at: this.expire_at as string,
      is_active: this.is_active as boolean,
      region: this.region,
      manager_id: this.manager_id,
      metadata: this.metadata,
      mfa_required_at: this.mfa_required_at,
      home_page: this.home_page,
    }

    if (this.station_id == null) {
      delete data.station_id
    }

    return data
  }

  public getOauthToken(provider: string): Promise<string | null> {
    if (!this.oauth_accounts.includes(provider)) {
      return Promise.reject(new Error('Provider not found'))
    }

    if (moment(this.web_oauth[provider].expire_at).isBefore(moment())) {
      return Promise.resolve(this.web_oauth[provider].token)
    }

    const api = new Api()

    return api.get(`oauth/${provider}/refresh`).then(response => {
      // Parse and Store User object
      const user = User.toObject(response.data.result.user)

      if (!user.web_oauth[provider].token) {
        return Promise.reject(new Error('Provider not found'))
      }

      getModule(SystemtModule).updateState({
        name: 'user',
        data: user,
      })

      return user.web_oauth[provider].token
    })
  }

  public changePassword(data: object) {
    const api = new Api()

    return api.patch(`user/${this.id}`, data).then(this.onChangePassword).catch(this.onError)
  }

  public resetPassword() {
    const api = new Api()

    return api
      .post('user/reset-password', {
        email: this.email,
      })
      .then(this.onChangePassword)
      .catch(this.onError)
  }

  public onChangePassword(response: any) {
    WebMessage.success('Password updated!')

    return response
  }

  public async lockUser(options: any) {
    const api = new Api()
    let _date: any = options.date ? { expire_at: options.date } : null
    if (options.enable) {
      if (!_date) _date = {}
      _date.enable = true
    }
    return api.post(`lock-user/${this.id}`, _date).then(this.onSave).catch(this.onError)
  }

  public static tableFields: any = tableFields
}
